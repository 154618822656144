/* You can add global styles to this file, and also import other style files */
@import 'assets/scss/custom-style.scss';
// @import "@ng-select/ng-select/themes/default.theme.css";

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// material autocomplete

.mat-option {
  height: 26px !important;
  font-size: 1.4rem;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: var(--white) !important;
	border: 1px solid var(--neutral-200) !important;
  box-shadow: unset;
  border-radius: 3px !important;
  height: 30px;
  .mat-icon-button {
    width: 30px !important;
    height: 30px !important;
  }
}

.mat-form-field-infix {
  padding: 0 0 2px 15px !important;
  height: 30px;
  border-top: 0;

  width: 100% !important;
}

.mat-input-element {
	caret-color: var(--neutral-200);
}

.mat-form-field {
  width: 100%;
}
.mat-form-field-ripple,
.mat-form-field-appearance-fill .mat-form-field-underline::before {
	background-color: transparent !important;
}

.mat-input-element  {
  height: 100%;
}

.mat-form-field-wrapper {
  padding: 0 !important;
}

tr {
  &:not(:first-child) {
    .mat-calendar-body-label {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.mat-datepicker-toggle-active {
  color: var(--neutral-300);
}
tr:nth-child(2) {
  .mat-calendar-body-label {
    padding-top: 0 !important;
  }
}

.mat-form-field-suffix {
  @media screen and (max-width: 1280px) {
    transform: translateY(-3px);
  }
}
.mat-form-field-infix {
  @media screen and (max-width: 1280px) {
    transform: translateY(1px);
    font-size: 1.2rem;
  }
}

.block-daterangepicker {
  .mat-form-field-infix {
    @media screen and (max-width: 1280px) {
      transform: initial;
    }
  }
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--neutral-200);
}

.mat-calendar-body-selected {
  background-color: var(--neutral-700) !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: var(--white) !important;
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--neutral-200);
  }
}
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--neutral-200)
}

@media(hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--neutral-200)
  }
}

.block-select-enterprise {
   .autocomplete-container {
    box-shadow: initial !important;
    border-bottom: 32px solid #c5c5c5;
    height: 30px;
    .suggestions-container {
      top: 32px;
      ul li a {
        padding: 2px 10px;
        color: var(--black);
      }
    }
    .input-container {
      input {
        &::placeholder {
          @media screen and (max-width: 1280px) {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
  .input-container {
    position: relative;
    input {
      height: 30px !important;
    }
  }
  [aria-autocomplete="list"] {
    padding-left: 0 !important;
  }
}

.mat-datepicker-toggle {
  .mat-icon-button {
    background-color: var(--neutral-200);
    border-radius: 0;
  }
}

.block-daterangepicker {
  margin-top: 0;
  .mat-form-field-appearance-fill .mat-form-field-flex,
  .mat-form-field-infix,
  .mat-form-field-appearance-fill .mat-form-field-flex .mat-icon-button {
    height: 40px !important;
  }
  .mat-form-field-appearance-fill .mat-form-field-flex,
  .mat-form-field-infix,
  .mat-form-field-appearance-fill .mat-form-field-flex .mat-icon-button {
    @media screen and (max-width: 1280px) {
      height: 35px !important;
    }
  }
  .mat-datepicker-toggle {
    position: absolute;
    top: 0;
    right: 0;
  }
  .mat-date-range-input,
  .mat-date-range-input-container {
    height: 100%;
  }
  &:hover {
    .mat-datepicker-toggle .mat-icon-button {
      background-color: var(--primary);
    }
  }
  .mat-datepicker-toggle-default-icon {
    @media screen and (max-width: 1280px) {
      width: 20px;
      position: relative;
      transform: translateY(-3px);
    }
  }
}
.mat-autocomplete-panel {
  min-width: 214px !important;
}

.cdk-overlay-container{
  z-index: 9999; //!important;
}

.mat-option-text {
  @media screen and (max-width: 1280px) {
    font-size: 1.1rem;
  }
}

.mat-calendar-body-in-range::before {
  background: var(--primary);
}

.mat-date-range-input-start-wrapper .mat-date-range-input-inner {
  @media screen and (max-width: 1280px) {
    top: -10px !important;
  }
}
.form-group-form-login {
  .autocomplete-container .input-container .x {
    right: 5px;
  }
}
.autocomplete-container .input-container .x {
  right: 23px;
  i {
    font-size: 17px !important;
  }
} 

.document-search-head {
  .block-daterangepicker {
    margin-top: 0;
    .mat-form-field-appearance-fill {
      transition: all .5s linear;
      &:hover {
        .mat-form-field-flex {
          .mat-button-base {
            background: var(--primary);
          }
        }
      }
      .mat-form-field-flex {
        border-radius: 5px !important;
        height: 42px !important;
        @media screen and (max-width: 1280px) {
          height: 33px !important;
        }
        .mat-button-base {
          border-radius: 0 5px 5px 0 !important;
          background: var(--neutral-500);
          // height: 39px !important;
          color: var(--white);
          @media screen and (max-width: 1280px) {
            height: 32px !important;
          }
        }
      } 
      .mat-datepicker-toggle-default-icon {
        @media screen and (max-width: 1280px) {
          width: 17px;
          transform: translateY(-5px);
        }
      }

    } 
    .mat-date-range-input-start-wrapper .mat-date-range-input-inner {
      top: 0 !important;
    }
  }
}