@use "variable";
@import "mixins";
@import "anim";

html {
  scroll-behavior: smooth;
}

body {
  font-family: variable.$fontFamily1;
  @include font-size(variable.$fontSize1);
  color: var(--black);
  line-height: variable.$lineHeight1;
  letter-spacing: variable.$lettreSpacing1;
  font-weight: variable.$normal;
  overflow-x: hidden;

  @include max(1280px) {
    @include font-size(variable.$fontSize3);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: variable.$fontFamily1;
  font-weight: variable.$normal;
  color: var(--black);
  line-height: variable.$lineHeight2;
  letter-spacing: variable.$lettreSpacing2;
  //text-transform: uppercase;
}

h1,
.title1 {
  @include font-size(variable.$fontSize-h1);
  margin-bottom: variable.$marginBottom-h1;
}

h2,
.title2 {
  @include font-size(variable.$fontSize-h2);
  margin-bottom: variable.$marginBottom-h2;
}

h3,
.title3 {
  @include font-size(variable.$fontSize-h3);
  margin-bottom: variable.$marginBottom-h3;
}

h4,
.title4 {
  @include font-size(variable.$fontSize-h4);
  margin-bottom: variable.$marginBottom-h4;
}

h5,
.title5 {
  @include font-size(variable.$fontSize-h5);
  margin-bottom: variable.$marginBottom-h5;
}

h6,
.title6 {
  @include font-size(variable.$fontSize-h6);
  margin-bottom: variable.$marginBottom-h6;
}

a {
  color: variable.$linkColor;
  text-decoration: variable.$linkTextDecoration;
  transition: all 0.3s ease-in-out;
}

a:hover {
  color: initial;
  text-decoration: variable.$linkTextDecoration;
}

strong,
b {
  font-weight: variable.$bold;
}

.size { 
  &-18 {
    font-size: 1.8rem;
  }
  &-17 {
    font-size: 1.7rem;
  }
  &-16 {
    font-size: 1.6rem;
  }
  &-15 {
    font-size: 1.5rem;
  }
  &-14 {
    font-size: 1.4rem!important;
  }
  &-13 {
    font-size: 1.3rem;
  }
  &-12 {
    font-size: 1.2rem;
  }
  &-11 {
    font-size: 1.1rem;
  }
}

.text {
  &-primary {
    color: var(--primary);
  }
  &-secondary-1 {
    color: var(--secondary-1);
  }
  &-secondary-2 {
    color: var(--secondary-2);
  }
  &-info {
    color: var(--info);
  }
  &-success {
    color: var(--success);
  }
  &-warning {
    color: var(--warning);
  }
  &-danger {
    color: var(--danger);
  }
  &-dark {
    color: var(--black)!important;
  }
  &-light {
      color: var(--neutral-7);
  }
  &-neutral-500{
    color: var(--neutral-500);
  }
  &-neutral-700{
    color: var(--neutral-700);
  }
}

/* ===== Scrollbar CSS ===== */

  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--neutral-500) var(--neutral-100);
  }

  /* Chrome, Edge, and Safari */

  *::-webkit-scrollbar-track {
    background: var(--neutral-100);
  }

  *::-webkit-scrollbar
  {
    width: 10px;
    height: 10px;
    background-color: #F5F5F5;
  }

  *::-webkit-scrollbar-thumb
  {
    background-color: var(--neutral-500);
    border: 2px solid var(--neutral-500);
  }

:focus {
  outline: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.form-control:focus {
  outline: 0;
  box-shadow: none;
}

textarea {
  resize: none;
}

button:focus {
  outline: none;
}

.btn,
a.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include font-size(variable.$btn-fontSize);
  font-weight: variable.$btn-fontWeight;
  line-height: variable.$btn-lineHeight;
  letter-spacing: variable.$btn-letterSpacing;
  color: variable.$btn-color !important;
  text-transform: variable.$btn-textTransform;
  background: variable.$btn-bg;
  text-decoration: none !important;
  @include padding(variable.$btn-padding);
  position: relative;
  border-radius: variable.$btn-borderRadius;
  border: variable.$btn-border;
  transition: all 0.3s ease-in-out;

  @include max(1280px) {
    padding: 0.7rem 2rem;
    border-radius: 3px;
    min-width: 89px !important;
    font-size: 1rem !important;
  }
}

.btn:hover {
  background: variable.$btn-bg-hover;
  border-color: variable.$btn-bg-hover;
}

.btn-custom,
a.btn-custom {
  color: variable.$btn2-color !important;
  background: variable.$btn2-bg;
  border-color: variable.$btn2-bg;
}

.btn-custom:hover {
  background: variable.$btn2-bg-hover;
  border-color: variable.$btn2-bg-hover;
}

.btn-lite,
a.btn-lite {
  color: variable.$btnLite-color !important;
  background: variable.$btnLite-bg;
}

.btn-lite:hover,
a.btn-lite:hover {
  color: variable.$btnLite-hover !important;
  background: variable.$btnLite-bg-hover;
  border-color: variable.$btnLite-bg;
}

.container {
  max-width: variable.$container;
  margin-left: auto;
  margin-right: auto;
  @include padding(variable.$containerPadding);
}

.mainColumn {
  display: flex;
  gap: 2rem;

  .itemCol {
    flex: 1 0 0%;
  }
}

.mainColumn2,
.mainColumn3,
.mainColumn4,
.mainColumn5 {
  display: grid;
  gap: 2rem;

  @include max(992px) {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  @include max(635px) {
    grid-template-columns: 1fr !important;
  }
}

.mainColumn2 {
  @include numberCol(2);
}

.mainColumn3 {
  @include numberCol(3);
}

.mainColumn4 {
  @include numberCol(4);
}

.mainColumn5 {
  @include numberCol(5);
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

#mainWrapper {
  @include padding(13rem 0 6rem);

  @include max(992px) {
    padding-top: 13rem;
  }
}

// debut creation popin creation shop

.main-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#111111, 0.75);
  z-index: 9999 !important;

  .cnt-modal {
    background: var(--white);
    width: 599px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: width ease-in-out 0.5s;
    transform: translate(-50%, -50%);
    border-radius: 5px 5px 5px 5px;
    .block-summary{
      max-height: 420px;
      overflow-y: auto;
      overflow-x: hidden;
      /* width */
        ::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #f1f1f1; 
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #888; 
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #555; 
        }
    }
    
    &.modal-lg {
      width: 764px;
    }

    &.modal-md {
      width: 598px;
    }

    &--large {
      width: 764px;
    }

    @include max(1280px) {
      // width: 507px; commenter pour eviter reduction de popin dan la page catalogue> getion d'article > rapport d'importation
    }

    &__header {
      background: var(--primary);
      border-radius: 5px 5px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      position: relative;
      margin-bottom: 10px;

      @include max(1280px) {
        @include font-size(2rem);
        margin-bottom: 0 !important;
        padding: 6px !important;
      }

      h3 {
        color: var(--black);
        font-family: var(--font-family1);
        font-weight: 700;
        @include font-size(1.8rem);
        text-transform: uppercase;
        margin: 0;

        @include max(1280px) {
          @include font-size(1.4rem);
        }
      }

      span {
        font-size: 27px;
        font-weight: 100;
        transform: rotate(45deg);
        position: absolute;
        cursor: pointer;
        right: 10px;

        @include max(1280px) {
          font-size: 19px;
        }
      }

      &--small {
        width: 420px !important;
      }
    }

    &__body {
      padding: 10px;

      // text-align: center;
      @include max(1280px) {
        font-size: 1.1rem;
        padding-bottom: 6px;
      }

      ul.list {
        text-align: left;
        padding-left: 15px;
        padding-top: 5px;

        li {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 9px;
            left: -9px;
            width: 5px;
            height: 5px;
            background: var(--neutral-500);
            border-radius: 50%;

            @include max(1280px) {
              top: 7px;
              left: -7px;
              width: 3.4px;
              height: 3.4px;
            }
          }
        }
      }

      .title-dialog {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.9;
        text-align: center;

        @include max(1280px) {
          font-size: 1.2rem;
        }
      }

      &-intern {
        padding: 0 20px 5px 20px;
        border-bottom: 1px solid var(--neutral-200);

        .intro {
          color: var(--black);
          font-weight: 600;
          font-family: var(--font-family1);
          margin-bottom: 10px;
          @include font-size(1.4rem);
          // text-align: center;

          @include max(1280px) {
            font-size: 1.2rem;
          }
        }

        .modal-content {
          .grid-aff-modal {
            display: grid;
            align-items: center;
            grid-gap: 20px;
            grid-template-columns: 140px 1fr;

            @include max(1280px) {
              @include font-size(1.1rem);
            }
          }

          .modal-icon {
            font-size: 76px;
            color: var(--neutral-500);
            text-align: center;
            position: relative;
            figure {
              width: 99px;
              height: 76px;
              margin: 0 auto;

              @include max(1280px) {
                width: 65px;
              }
            }
            &__icn-state {
              padding: 5px;
              border: 4px solid var(--neutral-500);
              font-size: 23px;
              position: absolute;
              border-radius: 50%;
              color: var(--neutral-500);
              background-color: var(--primary);
              top: 50%;
              right: 13px;
              transform: translateY(-50%);
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          &__list, & {
            font-weight: 700;
            @include font-size(1.4rem);

            ul {
              list-style-type: disc;

              li {
                word-break: break-word;

                &::marker {
                  color: var(--neutral-500);
                }

                margin-bottom: 10px;

                @include max(1280px) {
                  margin-bottom: 0;
                }

                .block-li-item {
                  display: flex;

                  @include max(1280px) {
                    transform: translateY(-4px) translateX(-5px);
                  }
                }

                span {
                  font-weight: 600;
                  margin-right: 10px;
                  @include font-size(1.2rem);
                  font-family: var(--font-family1);
                  width: 127px;
                  display: inline-block;

                  @include max(1280px) {
                    @include font-size(1.1rem);
                  }

                  &:nth-child(2) {
                    width: 60%;
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }

        &--cancel {
          text-align: center;

          .modal-content {
            display: block;
            @include font-size(1.4rem);
            color: var(--black);
            margin-bottom: 15px;
          }

          .intro {
            @include font-size(1.8rem);
          }
        }
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        // width: 148px;
        padding: 7px 20px;
        text-transform: uppercase;
        @include font-size(1.4rem);
        font-weight: 600;
        font-family: var(--font-family1);
        min-width: 127px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-right: 0; //pourair avoir impact quelque par, ajouter pour la page payement mobil money, chox de paiement mobile money, bouton en bas de page 

        @include max(1280px) {
          font-size: 1rem;
          padding: 4px 20px;
        }

        &:first-of-type {
          margin-right: 10px;
        }

        &-lite {
          color: var(--neutral-200);
          border: 1px solid var(--neutral-200);
          background: var(--white);

          &:hover {
            color: var(--black) !important;
            background: var(--neutral-100) !important;
            border: 1px solid transparent !important;
          }
        }

        &-yellow {
          color: var(--black) !important;
          background: var(--btn-primary);
          border: 1px solid transparent;

          &:hover {
            opacity: .7;
          }
        }
      }
    }

    // fin popin creation shop

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      outline: 0;
      box-shadow: none;
      border: 0 !important;
      background-image: none;
      flex: 1;
      padding: 0 .5em;
      cursor: pointer;
      font-size: .9em;
      font-family: var(--font-family1), sans-serif;
      border-radius: 5px;
      font-weight: 600;

      background: var(--white);
      color: var(--neutral-500);
    }

    select::-ms-expand {
      display: none;
    }

    .disabled {
      cursor: not-allowed;
    }

    button {
      cursor: pointer;
    }

    //debut popin erreur categorie article
    .cnt-modal__body-intern--import-error {
      .warning {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__icon {
          width: 41px;
          height: 41px;
          border-radius: 100%;
          border: 3px solid #c80f2d;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 15px;

          i {
            color: #c80f2d;
            font-size: 20px;
          }
        }

        &__text {
          color: var(--tertiary);
          text-transform: uppercase;
          font-weight: 600;
          font-family: var(--font-family1);
          margin-bottom: 15px;
          @include font-size(1.8rem);
        }
      }

      .intro {
        text-align: center;
        margin-bottom: 20px;
      }

      .modal-content__list--error {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
      }
    }

    //fin popin erreur categorie article

    //debut popin confirmation import cat article
    .cnt-modal__body-intern--import-article {
      .intro {
        text-align: center;
        font-weight: 600;
        margin-bottom: 20px;
        color: var(--black);
        @include font-size(1.8rem);

        .d-block {
          display: block;
        }
      }

      .modal-content {
        display: block !important;
        text-align: center !important;
        margin-bottom: 5px;
        font-weight: normal;
        @include font-size(1.4rem);
      }
    }

    //debut popin resumé importation cat article
    .modal-content__import-resume {
      .list-resume {
        display: flex; 
        grid-gap: 10px;
        justify-content: space-between; 
        ul {
          list-style: none;
          li {
            display: flex;
            align-items: center;
            &.color-danger {
              color: var(--danger);
            }

            span {
              width: 195px !important;
            }

            i {
              color: var(--neutral-200);
              font-size: 16px;
              margin-left: 10px;
            }
          }
        }
      }

      .download-resume {
        place-self: end;
        margin-bottom: 10px;

        .link-file {
          color: var(--black);
          font-weight: 600;
          @include font-size(1.1rem);
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    //fin popin resumé importation cat article
    .cnt-modal__header--small {
      width: 395px !important;
    }

    //debut popin ouverture caisse
    .cnt-modal__body-intern-caisse {
      padding: 0 !important;
    }

    .sale-modal {
      .modal-desc {
        text-align: center;
      }

      .intro {
        div {
          @include font-size(1.8rem);

          @include max(1280px) {
            @include font-size(1.4rem);
          }
        }
      }

      .modal-content {
        display: block !important;
        margin-bottom: 20px;
      }
    }

    .text-center {
      text-align: center;
    }

    .intro__child {
      @include font-size(1.8rem);

      @include max(1280px) {
        @include font-size(1.1rem);
      }
    }

    textarea {
      overflow: hidden;
    }

    .cnt-modal__header--interlocutor {
      width: 434px !important;
    }
  }

  // .modal-content {
  //   margin-bottom: 20px;
  //   @include max(1280px){
  //     margin-bottom: 10px;
  //   }
  // }

  .item-content-filtre {
    width: 100%;
    .block-flex {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
}

.sale-modal {
  .modal-desc {
    text-align: center;
  }

  .intro {
    div {
      @include font-size(1.8rem);
    }
  }

  .modal-content {
    display: block !important;
    margin-bottom: 20px;
  }
}

.text-center {
  text-align: center;
}

.intro__child {
  @include font-size(1.8rem);
}

textarea {
  overflow: hidden;
}

//fin popin ouverture caisse

// debut popin creation client
.cnt-modal__body--category-client {
  padding-top: 0 !important;

  .modal-content {
    display: block !important;
  }

  .cnt-modal__body-intern {
    padding: 0 0 5px 0px;
  }
}

.cnt-modal__header--new-client,
.cnt-modal__header--new-client-custom {
  width: 612px !important;
  left: 34% !important;
  top: 20% !important;
  transform: translate(-50%, -6%) !important;

  @include max(1200px) {
    width: 550px !important;
    left: 50% !important;
  }
}

.cnt-modal__header--new-client-custom {
  width: 780px !important;
}

textarea {
  overflow: hidden;
}

//fin popin ouverture caisse

// debut popin creation client
.cnt-modal__body--category-client {
  padding-top: 0 !important;

  .modal-content {
    display: block !important;
  }

  .cnt-modal__body-intern {
    padding: 0 0 5px 0px;
  }
}

.cnt-modal__header--new-client,
.cnt-modal__header--new-client-custom {
  width: 612px !important;
  left: 50% !important; //left: 34% !important; modifier en 50% par sylvano dans le vende cree nouvell client
  top: 20% !important;
  transform: translate(-50%, -6%) !important;

  @include max(1200px) {
    width: 550px !important;
    left: 50% !important;
  }
}

.cnt-modal__header--new-client-custom {
  width: 780px !important;
}

textarea {
  overflow: hidden;
}

.cnt-modal__header--interlocutor {
  width: 434px !important;
}

//debut popin payment
.cnt-modal__header--payment {
  width: 585px !important;
  left: 50% !important; //34 auparavant modifier en 50 par sylvano

  @include max(1200px) {
    left: 50% !important;
  }
}

.cnt-modal__body--payment {
  padding: 0 !important;

  .intro {
    margin: 0 !important;
  }

  .cnt-modal__body-intern {
    padding: 0;
    border-bottom: none !important;
  }

  .modal-content {
    display: block !important;
  }
}

textarea {
  overflow: hidden;
}

.cnt-modal__header--interlocutor {
  width: 434px !important;
}

select {
  background: var(--white);
}

.disabled {
  cursor: not-allowed !important;
  pointer-events: none;
}

//popin document facture
.cnt-modal__body--generate {
  width: 59.583vw !important;
  // left: 34% !important; (conflit avec .main-modal .cnt-modal)

  &-intern {
    .cnt-modal__body-intern {
      padding: 0 10px 5px 10px !important;
      border-bottom: none !important;

      @include max(1280px) {
        padding: 0 !important;
      }
    }
  }

  @include max(1024px) {
    left: 32% !important;
  }

  .intro {
    margin-bottom: 0 !important;
  }

  .cnt-modal__footer {
    justify-content: flex-end !important;

    button {
      background: var(--neutral-500);

      &:hover {
        background: var(--primary);
        color: var(--black) !important;
      }

      color: var(--white) !important;
    }
  }

  .modal-content {
    display: block !important;
  }

  .cnt-modal__header {
    margin-bottom: 0 !important;
  }
}

.cnt-modal__header--serialisation {
  width: 744px !important;
  left: 50% !important;
  top: 20% !important;
  transform: translate(-50%, -6%) !important;

  @include max(1200px) {
    width: 550px !important;
    left: 50% !important;
  }
}

.cnt-modal__body--serialisation {
  padding-top: 0 !important;

  .modal-content {
    display: block !important;
  }

  .cnt-modal__body-intern {
    padding: 0 0 5px 0px;
  }
}


textarea {
  overflow: hidden;
}

.cnt-modal__header--interlocutor {
  width: 434px !important;
}

img[alt="logo"] {
  max-width: 50px;
}

.arrowRight {
  border: solid #4a4b4dc2;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  position: absolute;
  right: 15px;

  @include max(1280px) {
    right: 4px;
  }
}

::selection {
  background-color: #1a4ebe73;
  color: var(--white);
}

.cnt-modal__header--interlocutor {
  width: 434px !important;
}

.lc-4 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  padding-bottom: 0 !important;
}

.lc-3 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  padding-bottom: 0 !important;
}

.lc-2 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding-bottom: 0 !important;
}

.lc-1 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding-bottom: 0 !important;
}

// switch start
.block-switch {
  display: flex;

  >.switch {
    display: block;
  }

  .switch {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 70px;
    height: 16px;
    background-color: white;
    border-radius: 18px;
    box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    background-image: -webkit-linear-gradient(top, #eeeeee, white 25px);
    background-image: -moz-linear-gradient(top, #eeeeee, white 25px);
    background-image: -o-linear-gradient(top, #eeeeee, white 25px);
    background-image: linear-gradient(to bottom, #eeeeee, white 25px);

    // @include max(1280px) {
    //   height: 10px;
    //   width: 34px;
    // }
  }

  .switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    background: var(--neutral-700);
    border-radius: inherit;
    -webkit-transition: 0.15s ease-out;
    -moz-transition: 0.15s ease-out;
    -o-transition: 0.15s ease-out;
    transition: 0.15s ease-out;
    -webkit-transition-property: opacity background;
    -moz-transition-property: opacity background;
    -o-transition-property: opacity background;
    transition-property: opacity background;
    width: 100%;

    @include max(1280px) {
      // width: 34px;
      // font-size: 6px;
    }
  }

  .switch-label:before,
  .switch-label:after {
    position: absolute;
    top: 50%;
    margin-top: -0.56em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
  }

  .switch-label:before {
    content: attr(data-off);
    right: 17px;
    color: var(--white);

    @include max(1280px) {
      // right: 11px;
    }
  }

  .switch-label:after {
    content: attr(data-on);
    left: 19px;
    color: var(--neutral-black);;
    opacity: 0;

    @include max(1280px) {
      // left: 15px;
    }
  }

  .switch-input:checked~.switch-label {
    background: #47a8d8;
  }

  .switch-input:checked~.switch-label:before {
    opacity: 0;
  }

  .switch-input:checked~.switch-label:after {
    opacity: 1;
  }

  .switch-handle {
    position: absolute;
    top: 1px;
    // left: 1px;
    right: 0;
    width: 14px;
    height: 14px;
    background: white;
    border-radius: 10px;
    background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
    background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
    background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
    background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
    -webkit-transition: left 0.15s ease-out;
    -moz-transition: left 0.15s ease-out;
    -o-transition: left 0.15s ease-out;
    transition: left 0.15s ease-out;

    @include max(1280px) {
      // top: 0.2px;
      // width: 8px;
      // height: 8px;
    }
  }

  .switch-input:checked~.switch-handle {
    left: 1px;
  }

  .switch-primary>.switch-input:checked~.switch-label {
    background: var(--primary);
  }

  &.lg-switch {
    display: inline;

    .switch {
      position: relative;
      display: inline-block;
      vertical-align: top;
      min-width: 106px;
      height: 27px;
      background-color: white;
      border-radius: 18px;
      box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
      cursor: pointer;
      background-image: -webkit-linear-gradient(top, #eeeeee, white 25px);
      background-image: -moz-linear-gradient(top, #eeeeee, white 25px);
      background-image: -o-linear-gradient(top, #eeeeee, white 25px);
      background-image: linear-gradient(to bottom, #eeeeee, white 25px);

      .switch-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        &:checked~.switch-label {
          background: #47a8d8;
        }

        &:checked~.switch-label:before {
          opacity: 0;
        }

        &:checked~.switch-label:after {
          opacity: 1;
        }

        &:checked~.switch-handle {
          left: 3px;
        }
      }

      .switch-label {
        position: relative;
        display: block;
        height: inherit;
        font-size: 1.4rem;
        font-weight: 600;
        background: var(--neutral-700);
        border-radius: inherit;
        -webkit-transition: 0.15s ease-out;
        -moz-transition: 0.15s ease-out;
        -o-transition: 0.15s ease-out;
        transition: 0.15s ease-out;
        -webkit-transition-property: opacity background;
        -moz-transition-property: opacity background;
        -o-transition-property: opacity background;
        transition-property: opacity background;
        width: 100%;


        &.not-vendable {
          &:before {
            content: attr(data-off);
            right: 25px;
            color: var(--white);
          }
        }

        &:before,
        &:after {
          position: absolute;
          top: 50%;
          margin-top: -0.5em;
          line-height: 1;
          -webkit-transition: inherit;
          -moz-transition: inherit;
          -o-transition: inherit;
          transition: inherit; 
        }

        &:before {
          content: attr(data-off);
          right: 34px;
          color: var(--white);
        }

        &:after {
          content: attr(data-on);
          left: 33px;
          color:var(--neutral-black);
          opacity: 0;
        }
      }

      .switch-handle {
        position: absolute;
        top: 3px;
        right: 3px;
        width: 20px;
        height: 20px;
        background: white;
        border-radius: 10px;
        background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
        background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
        background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
        background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
        -webkit-transition: left 0.15s ease-out;
        -moz-transition: left 0.15s ease-out;
        -o-transition: left 0.15s ease-out;
        transition: left 0.15s ease-out;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      }

      &.switch-primary>.switch-input:checked~.switch-label {
        background: var(--primary);
      }

    }
  }

  &.md-switch {
    display: inline;

    .switch {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 54px;
      height: 27px;
      background-color: white;
      border-radius: 18px;
      box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
      cursor: pointer;
      background-image: -webkit-linear-gradient(top, #eeeeee, white 25px);
      background-image: -moz-linear-gradient(top, #eeeeee, white 25px);
      background-image: -o-linear-gradient(top, #eeeeee, white 25px);
      background-image: linear-gradient(to bottom, #eeeeee, white 25px);

      .switch-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        &:checked~.switch-label:before {
          opacity: 0;
        }

        &:checked~.switch-label:after {
          opacity: 1;
        }

        &:checked~.switch-handle {
          left: 31px;
        }
      }

      .switch-label {
        position: relative;
        display: block;
        height: inherit;
        font-size: 1.4rem;
        font-weight: 600;
        border-radius: inherit;
        -webkit-transition: 0.15s ease-out;
        -moz-transition: 0.15s ease-out;
        -o-transition: 0.15s ease-out;
        transition: 0.15s ease-out;
        -webkit-transition-property: opacity background;
        -moz-transition-property: opacity background;
        -o-transition-property: opacity background;
        transition-property: opacity background;
        width: 54px;

        &:before,
        &:after {
          position: absolute;
          top: 50%;
          margin-top: -0.5em;
          line-height: 1;
          -webkit-transition: inherit;
          -moz-transition: inherit;
          -o-transition: inherit;
          transition: inherit;
        }

        &:before {
          content: attr(data-on);
          right: 64px;
          color: var(--black);
        }

        &:after {
          content: attr(data-off);
          left: 65px;
          color: var(--black);
          opacity: 0;
        }
      }

      .switch-handle {
        position: absolute;
        top: 3px;
        right: 31px;
        width: 21px;
        height: 21px;
        background: var(--neutral-700);
        border-radius: 10px;
        transition: left 0.15s ease-out;
        border: 2px solid var(--white);
      }

      &.switch-primary>.switch-input~.switch-label {
        background: var(--neutral-200);
        box-shadow: inset -3px 3px 6px #00000029;
      }

    }
  }

  &.disabled {
    filter: grayscale(1);
    opacity: .5;
    pointer-events: none;
    cursor: initial;
  }
}

// switch end

.block-on-off {
  display: flex;
  align-items: center;
  column-gap: 10px;

  @include max(1280px) {
    transform: scale(0.8);
  }

  label {
    font-size: 1.8rem;
    font-weight: 600;
  }
  &.label-top{
    display: block;
    >label{
      display: block;
      margin-bottom: 5px;
      line-height: 1.4rem;
    }
  }
}

.bloc-card_top-item_right {
  position: absolute;
  right: 5px;
  top: 3px;
  height: 36px;

  @include max(1280px) {
    top: 0;
  }

  &:hover {
    .block-sub {
      display: block;
    }
  }
}

.icn-etc {
  font-size: 5px;
  color: #666;

  @include max(1280px) {
    font-size: 3px;
  }
}

.block-sub {
  position: absolute;
  right: 0;
  width: 114px;
  display: none;
  top: 28px;
  z-index: 1;

  @include max(1280px) {
    width: 95px;
    right: -4px;
    top: 23px;
  }

  &-content {
    background: var(--neutral-100);
    border-radius: 3px;
    font-size: 12px;
    box-shadow: 0px 1px 6px #00000061;

    @include max(1280px) {
      font-size: 1rem;
    }

    &_item {
      display: flex;
      column-gap: 8px;
      padding: 4px 12px;
      align-items: center;

      @include max(1280px) {
        padding: 2px 4px;
      }

      span {
        &:first-child {
          font-size: 17px;

          @include max(1280px) {
            font-size: 12px;
          }
        }

        &.icn-see-detail {
          font-size: 10px;
          font-weight: 700;

          @include max(1280px) {
            font-size: 6px;
          }
        }
      }
    }

    .block-sub-content_item {
      transition: all 0.2s ease-in-out;

      &:hover {
        color: initial;
        background: var(--primary);
      }
    }
  }
}

// tooltip rounded color flex
.list-social {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 6px;

  .item-social {
    width: 11px;
    height: 11px;

    @include max(1280px) {
      width: 5px;
      height: 5px;
    }

    .link-colored {
      height: 100%;
      background: var(--primary);
      border-radius: 50%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
    }
  }
}

// tooltip rounded color
.tooltip-wrapper_rounded-color {
  display: flex;
  align-items: center;
  justify-content: center;

  [title] {
    &:hover {

      &::after,
      &::before {
        opacity: 1;
      }
    }
  }
}

.tooltip-wrapper_rounded-color {
  span {
    font-size: 16px;
    color: var(--neutral-200);
    border: none;
    outline: none;
    width: 11px;
    height: 11px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;

    @include max(1280px) {
      width: 7px;
      height: 7px;
    }
  }

  [title] {
    position: relative;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: -10px;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   border-width: 8px 6px 0 6px;
    //   border-style: solid;
    //   border-color: rgba(#4F4F4F, 0.85) transparent transparent transparent;
    //   z-index: 99;
    //   opacity: 0;
    // }

    &::after {
      content: attr(title);
      box-shadow: 0px 0px 6px #00000029;
      font-family: var(--font-family1);
      font-size: 10px;
      position: absolute;
      // left: 50%;
      left: 67px;
      // top: -10px;
      top: 16px;
      transform: translateX(-50%) translateY(-100%);
      background: rgba(#4F4F4F, 0.85);
      text-align: center;
      color: var(--white);
      padding: 4px 2px;
      min-width: 103px;
      border-radius: 3px;
      pointer-events: none;
      padding: 4px 4px;
      z-index: 99;
      opacity: 0;

      @include max(1280px) {
        font-size: 9px;
        padding: 4px 2px;
        min-width: 90px;
      }
    }

    &:hover {

      &::after,
      &::before {
        opacity: 1;
      }
    }
  }

  a {
    &:hover {
      border: 1px solid var(--black);
    }
  }
}

.block-flex {
  display: flex;
  column-gap: 4px;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;

  &--center {
    justify-content: space-around;
  }

  figure {
    display: flex;

    img {
      width: 19px;

      @include max(1280px) {
        width: 12px;
      }
    }

    &.img-filter {
      // filter: opacity(0.9);
    }
  }

  &:last-child {
    color: #9e9e9e;
    font-size: 11px;
  }
}

.hidden {
  display: none;
}

// info alert
.paragraph-info {
  margin-bottom: 10px;

  @include max(1280px) {
    font-size: 12px;
  }

  span {
    color: var(--neutral-700);

    em[class^="icn-"] {
      color: #4a4b4d50;
      margin-right: 10px;
      font-size: 16px;

      @include max(1280px) {
        font-size: 13px;
      }
    }

    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    background: #fccc0025;
    padding: 5px 10px;
    @include font-size(1.3rem);
    font-family: var(--font-family1);

    @include max(1280px) {
      font-size: 1.1rem;
    }

    a {
      text-decoration: underline;
    }
  }
}

.title-right {
  border-bottom: 1px solid var(--neutral-200);
  padding-bottom: 5px;
  margin-bottom: 20px;

  h2 {
    color: var(--black);
    font-size: 1.8rem;
    font-family: var(--font-family1);
    font-weight: 600;
    margin: 0;
  }
}

.block-flex-wrap-22 {
  display: flex;
  column-gap: 22px;
  row-gap: 22px;
  flex-wrap: wrap;
}

.block-counter-left {
  position: relative;

  .icon-bg:nth-child(2) {
    position: absolute;
    top: 19px;
    right: 3px;
    background: var(--white) !important;
    z-index: 1;
    width: 15px !important;
    height: 15px !important;
    font-size: 6px;
    border: 2px solid var(--primary);

    // @include max(1280px) {
    //   top: 12px;
    //   right: -3px;
    //   width: 11px !important;
    //   height: 11px !important;
    // }
    // @include max(1100px) {
    //   top: 19px;
    //   width: 15px !important;
    //   height: 15px !important;
    // }
    .icn-icon_right_rrow {
      font-size: 5px !important;
    }

    i[class^="icn"] {
      font-size: 0.7rem;
      font-weight: 800;
      color: var(--black);
      // @include max(1280px) {
      //   font-size: 0.5rem !important;
      // }
    }
  }
}

// tooltip for text

[data-tooltip] {
  position: relative;
  cursor: pointer;

  &:not(.tooltip-disabled) {

    &:before,
    &:after {
      line-height: 1.1;
      font-size: 0.9em;
      pointer-events: none;
      position: absolute;
      box-sizing: border-box;
      display: none;
      opacity: 0;
    }

    &:before {
      content: "";
      border: 5px solid transparent;
      z-index: 100;
    }

    &:after {
      content: attr(data-tooltip);
      text-align: center;
      min-width: 3em;
      max-width: max-content;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // padding: 9px 16px;
      width: 121px;
      white-space: break-spaces;
      overflow: initial;
      padding: 5px 6px;

      border-radius: 3px;
      background: #454648d1;
      color: var(--white);
      z-index: 99;
      font-size: 9px;
      font-weight: normal !important;
      font-family: var(--font-family1);
    }

    &:hover:before,
    &:hover:after {
      display: block;
      opacity: 1;
    }

    &:not([data-flow])::before,
    &[data-flow="top"]::before {
      bottom: 100%;
      border-bottom-width: 0;
      border-top-color: #454648d1;
    }

    &:not([data-flow])::after,
    &[data-flow="top"]::after {
      bottom: calc(100% + 4px);
    }

    &:not([data-flow])::before,
    [tooltip]:not([data-flow])::after,
    &[data-flow="top"]::before,
    &[data-flow="top"]::after {
      left: 50%;
      -webkit-transform: translate(-50%, 3px);
      transform: translate(-50%, 0px);
    }

    &[data-flow="bottom"]::before {
      top: 100%;
      border-top-width: 0;
      border-bottom-color: #454648ff;
    }

    &[data-flow="bottom"]::after {
      top: calc(100% + 5px);
    }

    &[data-flow="bottom"]::before,
    &[data-flow="bottom"]::after {
      left: 50%;
      -webkit-transform: translate(-50%, 8px);
      transform: translate(-50%, 8px);
    }

    &[data-flow="left"]::before {
      top: 50%;
      border-right-width: 0;
      border-left-color: #454648ff;
      left: calc(0em - 5px);
      -webkit-transform: translate(-8px, -50%);
      transform: translate(-8px, -50%);
    }

    &[data-flow="left"]::after {
      top: 50%;
      right: calc(100% + 5px);
      -webkit-transform: translate(-8px, -50%);
      transform: translate(-8px, -50%);
    }

    &[data-flow="right"]::before {
      top: 50%;
      border-left-width: 0;
      border-right-color: #454648ff;
      right: calc(0em - 5px);
      -webkit-transform: translate(8px, -50%);
      transform: translate(8px, -50%);
    }

    &[data-flow="right"]::after {
      top: 50%;
      left: calc(100% + 5px);
      -webkit-transform: translate(8px, -50%);
      transform: translate(8px, -50%);
    }
  }
  &.tooltip-disabled {
    button {
      cursor:not-allowed;
      &:hover {
        background: var(--neutral-200);
      }
    }
  }

}

[data-tooltip=""]::after,
[data-tooltip=""]::before {
  display: none !important;
}

ng-select {
  .ng-option {
    font-size: 1.2rem;
    padding: 4px 8px;
    color: var(--black);

    &:hover {
      background-color: var(--neutral-100);
    }
  }

  .ng-dropdown-panel {
    background-color: var(--white);
    box-shadow: 0px 0px 3px #0000000d;
    border: 1px solid var(--neutral-100);
    border-radius: 0 0 5px 5px;
    width: 100%;
    left: 0;
    top: 38px;
  }

  .ng-clear-wrapper {
    display: none;
  }
}

.not-visible {
  display: none !important;
}

.intro-table {
  font-size: 18px;
  text-align: center;
  @include max(1280px) {
    font-size: 14px;
  }
}

// table
.block-table {
  height: 212px;
  overflow-y: auto;

  .table-profil-utilisateur {
    border-collapse: separate;
    width: 100%;

    thead {
      font-size: 1.6rem;
      font-weight: 600;
      position: sticky;
      top: -2px;
      left: 0;
      background-color: var(--white);
      z-index: 1;
    }

    tbody {
      font-size: 14px;
    }

    table {
      width: 750px;
      border-collapse: collapse;
      margin: 50px auto;
    }

    th {
      font-weight: bold;
    }

    td,
    th {
      padding: 10px 18px;
      text-align: left;
      font-size: 18px;
    }

    th {
      padding: 0 18px;
      border-top: 1px solid var(--neutral-500);
      border-bottom: 1px solid var(--neutral-500);
    }

    td {
      font-size: 14px;
      border-bottom: 1px solid #cccccc47;
    }

    tr {
      td:last-child {
        text-align: center;
      }
    }

    .tri-table {
      padding-left: 25px;
      cursor: pointer;
    }
  }
}




// start yellow check
.cell-table {
  .container {
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.disabled{
      cursor:not-allowed; 
      color: var(--neutral-200)!important; 
    }
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: var(--white);
    border-radius: 3px;
    border: 1px solid var(--neutral-500);

    @include max(1280px) {
      // height: 12px;
      // width: 12px;
    }
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked~.checkmark {
    background-color: var(--primary);
    border: 1px solid var(--neutral-500);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid var(--neutral-700);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    @include max(1280px) {
    // left: 3.4px;
    // top: 0px;
    // width: 4px;
    // height: 8px;
    }
  }
  input{
    &[type=radio]{
       ~ .checkmark{ 
        border-radius: 50%;
        &::after{
          border: 0;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background: var(--neutral-700);
          top: 3px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      } 
    }
  &[type=radio]:disabled, &[type=checkbox]:disabled{
       ~ .checkmark{ 
        border-color: var(--neutral-300);
        background-color: var(--neutral-200);
        cursor: not-allowed;
       }
      } 
   
  }
  // end yellow check
}

.block-content-shop {
  .shop-list__counter {
    width: 282px !important;
    height: 86px;
    margin-top: 20px;
    margin-right: 20px;
  }
}

.icn-sm {
  i {
    margin-bottom: -6px;
    transform: scale(0.7);
  }
}

#pack-add-item-id {
  .cnt-modal__footer {
    justify-content: center !important;

    button {
      color: var(--black) !important
    }
  }
}

.block-center {
  display: flex;
  justify-content: center;
  margin: 0 20px;

  .btn.btn-show-more {
    background: var(--neutral-100);
    color: var(--neutral-500) !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.4rem;
    cursor: pointer;
  }
}

// start black check
.cell-table.black-check {
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .hide {
      opacity: 0;
    }
  }

  /* Hide the browser's default checkbox */
  .container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 17px;
    width: 17px !important;
    background-color: var(--white);
    border-radius: 3px;
    border: 1px solid var(--neutral-500);
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked~.checkmark {
    background-color: var(--black);
    border: 0;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 11px;
    border: solid var(--white);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  // end yellow check
}

.result-filtre {
  color: #878787;
  font-size: 1.4rem;
  display: flex;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
  align-items: center;

  @include max(1280px) {
    font-size: 1.2rem;
  }

  @include max(1100px) {
    font-size: unset;
  }

  .block-word,
  .block-delete-all {
    font-size: 1.2rem;
    align-items: center;

    @include max(1280px) {
      font-size: 1.1rem;
    }

    @include max(1100px) {
      font-size: unset;
    }
  }

  .block-word {
    display: flex;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;

    .item-content-word {
      display: flex;
      column-gap: 10px;
      row-gap: 10px;
      border: 1px solid var(--neutral-100);
      border-radius: 3px;
      padding: 3px 6px;
      position: relative;
      padding-right: 24px;
      min-height: 24px;
      cursor: pointer;

      .icon-close {
        font-size: 20px;
        font-weight: 100;
        transform: rotate(45deg);
        position: absolute;
        cursor: pointer;
        right: 6px;
        top: -3px;
        font-weight: 700;
        color: #020304;
      }
    }
  }

  .block-delete-all {
    .block-delete-all-content {
      display: flex;
      column-gap: 8px;
      align-items: center;
      cursor: pointer;

      span {
        &:nth-child(2) {
          text-decoration: underline;
        }
      }
    }
  }
}

.block-network-selected {
  .top-title-network-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title-network-selected {
    font-size: 1.4rem;
    font-weight: 600;
  }
}


.semi_bold {
  font-weight: 600;
}

.has-child {
  position: relative;

  &::before {
    content: "";
    border: solid #474747;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    position: absolute;
    // top: 11px;
    // left: 28px;
  }

  &.open {
    &::before {
      transform: rotate(45deg);
    }
  }

  &:not(.open) {
    &::before {
      transform: rotate(-45deg);
    }
  }
}


.radio-custom {
  ul {
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
    justify-content: right;

    @include max(1280px) {
      padding-bottom: 7px;
      margin-bottom: 6px;
    }

    li {
      border: 1px solid var(--black);
      color: var(--black);
      margin-right: 0;
      background: var(--white);
      width: 136px;
      font-weight: 600;
      @include font-size(1.1rem);
      text-align: center;
      padding: 5px 10px;
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      @include max(1280px) {
        width: 98.3px;
        font-size: 1.1rem;
      }

      &.active {
        background: var(--neutral-700);
        color: var(--white);
        border: 1px solid transparent;
      }

      &:first-child {
        border-radius: 3px 0px 0px 3px;
      }

      &:last-child {
        border-radius: 0px 3px 3px 0px;
      }
    }
  }
}

.btn {
  &.btn-dark {
    background-color: var(--neutral-700);
    color: #f0f0f0 !important;

    &.desable-btn {
      pointer-events: none;
      box-shadow: inset 0px 3px 6px #00000029;
      background: var(--neutral-100);
      color: var(--neutral-200) !important;
    }
  }

  &.btn-default-lite {
    border: 1px solid var(--neutral-500);
    background-color: transparent;
    color: var(--neutral-500) !important;
    font: normal normal 600 11px/10px var(--font-family1);
  }

}

.card-disabled {
  background-color: #f2f3f4;
  color: #b9babb;
  border: 0 !important;

  &.rockerState,
  &:hover {
    background-color: #f2f3f4 !important;
  }
}

.card-chip-article,
.pack-chip-article {
  .chip-article {

    .title-chip-article,
    .text-chip-article {
      font-size: 8px;
      letter-spacing: .16px;
      text-transform: uppercase;
      color: var(--white);
      border: none;
      outline: none;
      width: max-content;
      height: max-content;
      display: flex;
      justify-content: center;
      border-radius: 3px;
      padding: 1px 4px 2px 4px;
      background-color: var(--tertiary);
      position: absolute;
      right: 0;
      bottom: -5px;
    }

    .tooltip-wrapper_rounded-color [title] {
      &::after {
        min-width: 190px;
        left: -84px;
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      &::before {
        left: -18px;
      }
    }
  }

  .block-intern-top-item-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.semibold {
  font-weight: 600;
}

app-product-card,
app-pack-card {
  position: relative;

  &:first-child {
    .tooltip-wrapper_rounded-color [title] {
      &::before {
        left: -61px !important;
        top: 1px;
        transform: rotate(-90deg);
      }

      &::after {
        left: -154px !important;
        top: 30px;

        @include max(1280px) {
          left: -153px !important;
          top: 16px;
        }

      }
    }
  }
}

app-pack-card {
  position: relative;

  &:first-child {
    .tooltip-wrapper_rounded-color {
      justify-content: end;

      [title] {
        &::before {
          left: -61px !important;
          top: 1px;
          transform: rotate(-90deg);
        }

        &::after {
          left: -154px !important;
          top: 16px;
        }
      }
    }
  }

  // &:first-child {
  .tooltip-wrapper_rounded-color {
    justify-content: end;

    [title] {
      &::before {
        left: -61px !important;
        top: 1px;
        transform: rotate(-90deg);
      }

      &::after {
        left: -154px !important;
        top: 16px;
      }
    }
  }

  // }
}

.tr-disabled {
  min-height: 84px;
  background-color: var(--neutral-100);
  color: #A1A4AA;

  &:hover {
    background-color: var(--neutral-100) !important;
  }

  &.tr-disabled-expanded {
    background-color: var(--custom-bg-space-sale);

    &:hover {
      background-color: var(--custom-bg-space-sale) !important;
    }
  }

  .icn-delete {
    border: 1px solid var(--white);
  }

  // ~ .isExpand {
  //   background-color: var(--custom-bg-space-sale);
  //   opacity: .25;
  //   &:hover {
  //     background-color: var(--custom-bg-space-sale) !important;
  //     opacity: .25;
  //   }
  // }
}

.cell-content {
  display: flex;
  align-items: center;
  column-gap: 9px;
  width: fit-content;

  .icn-edit,
  .icn-delete,
  .icn-see-detail {
    background: var(--neutral-200);
    padding: 5.7px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
    color: var(--neutral-black);

    &:hover {
      background-color: var(--primary) !important;
    }
  }

  .user-avatar {
    display: flex;
  }
}

.chip-colored {
  font-size: 8px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: var(--white);
  border: none;
  outline: none;
  width: max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  padding: 2px 4px 2px 4px;
  background-color: var(--tertiary);
}

.error-history {
  .block-pack-name {
    color: var(--secondary-1);
    font-weight: 600;
  }
}

.error{
  color: var(--danger);
  font-weight: 400;
  font-size: 12px;
}

.desabled-block {
  color: var(--neutral-500) !important;
  pointer-events: none;
}

.input-error {
  border-color: var(--danger) !important;
}

.badge {
  border-radius: 3px !important;
  padding: 2px 9px !important;
  text-transform: inherit;
  color: var(--white);
  border: 1px solid var(--neutral-100);
  min-width: 103px;
  display: block;
  text-align: center;

  &.bg-success {
    background-color: var(--success);
  }

  &.bg-danger {
    background-color: var(--danger)
  }

  &.bg-default {
    background-color: #95BFFF;
  }

  &.bg-warning {
    background-color: #FFEA94;
  }

  &.bg-semantic-warning {
    background-color: var(--warning);
  }

  &.bg-info {
    background-color: var(--info);
  }
}

.padding-lt {
  padding-top: 4px;
  margin-left: -13px;
}
.block-historique{
  .vendor-only{
    display: flex!important;
    width: auto;
    @include max(1280px) {
      gap: 20px!important;
    }

   
    .shop-list__counter{
      width: auto!important;
      min-width: 240px!important;
      position: relative;
      .counter-label-desc{
        white-space: nowrap;
      }
      .dateKpi {
        position: absolute;
        font-size: 12px;
        right: 10px;
        bottom: 5px;
      }
    }
  }
}
.block-column-20 {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  .content-product {
    display: grid;
    grid-template-columns: 28.9% 40% 1fr;
    grid-gap: 50px;
 
    @include max(1024px) {
      grid-template-columns: 100%;
      grid-gap: 0;
      width: 59.3%;
    }

    .column-container {
      display: grid;
      grid-template-columns: 25.417vw 1fr;
      grid-gap: 50px;

      @include max(1280px) {
        grid-gap: 24px;
      }

      @include max(1024px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
    }
  }
}

.main-content-shop {
  // display: flex;
  // column-gap: 50px;

  // > div {
  //   &:first-child {
  //     width: 488px;
  //   }
  // }
  // @include max(1024px) {
  //   grid-template-columns: 1fr;
  //   column-gap: 0;
  //   // width: 60.8%;
  // }
  display: grid;
  grid-template-columns: 28.9% 40% 1fr;
  grid-gap: 50px;
  &.vendor-only{
    display: flex;
    width: auto;
    .shop-list__counter{
      width: auto!important;
      min-width: 240px!important;
      .counter-label-desc{
        white-space: nowrap;
      }
    }
  }
  @include max(1024px) {
    grid-template-columns: 100%;
    grid-gap: 0;
    width: 59.3%;
  }
}

.p-info {
  font-size: 1.8rem;
  text-align: center;

  @include max(1280x) {
    font-size: 1.5rem;
  }
}

.hide {
  display: none !important;
}

.block-summary {

  .table-summary {
    margin-top: 9px;

    table {
      width: 100%;

      .input-valid {
        border: 1px solid var(--neutral-200);
        border-radius: 5px;
        width: 100%;
        text-align: center;
        font-size: 1.4rem;
      }

      .blockCenter {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      div.bold {
        display: inline-block;
        margin-right: 10px;
      }

      .bold {
        font-weight: 700;

        div {
          border-radius: 50%;
          width: 18px;
          height: 18px;
          background: var(--neutral-100);
          font-weight: 400;
          margin-right: 10px;

          @include max(1024px) {
            margin-right: 3px;
          }

          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }

      thead {
        background: var(--neutral-100) !important;
        z-index: 1;

        th {
          vertical-align: middle;
          color: var(--black);
          padding-right: 20px;

          @include max(1440px) {
            vertical-align: baseline;
            padding-top: 10px;
            padding-bottom: 5px;
            line-height: 1.3;
          }

          @include max(1280px) {
            @include font-size(1.1rem);
          }

          &:not(:first-child) {
            min-width: 54px;
          }

          &:first-child {
            padding-left: 9px;
            font-weight: 600;

            @include max(1280px) {
              padding-left: 10px;
            }

            @include max(1024px) {
              min-width: 70px;
            }

            position: relative;
          }

          &:nth-child(2) {
            position: relative;
          }

          @include max(1440px) {
            &:nth-child(4) {
              min-width: 50px;
            }

            &:nth-child(6) {
              min-width: 125px;
            }

            &:nth-child(3) {
              text-align: right !important;
            }
          }

          @include max(1024px) {
            &:nth-child(5) {
              text-align: center !important;
              padding-right: 30px !important;
            }

            &:nth-child(6) {
              min-width: initial;
              padding-right: 10px !important;
            }

            &:nth-child(3) {
              text-align: right !important;
              padding-right: 20px;
            }
          }

          &:nth-child(6),
          &:nth-child(5) {
            padding-right: 20px;
          }

          text-align: left;
          height: 42px;
          font-family: var(--font-family1);
          @include font-size(1.4rem);
          font-weight: 600;

          @include max(1280px) {
            height: initial;
            font-size: 1.1rem;
            padding-bottom: 8px;
            padding-top: 7px;
          }

          span {
            color: var(--neutral-700);
            font-weight: 400;
            @include font-size(1.4rem);

            @include max(1280px) {
              font-size: 1.2rem;
            }

            @include max(1024px) {
              @include font-size(1rem);
              display: block;
            }
          }
        }
      }

      tbody {
        background: var(--white);
        font-weight: normal;

        tr {
          border-bottom: 1px solid var(--neutral-100);
          position: relative;

          // height: 50px;
          .tag {
            color: var(--white);
            padding: 3px 8px;
            border-radius: 3px;
            display: inline-block;
            background: var(--secondary-2);
            @include font-size(1.1rem);
          }

          &:hover {
            background: var(--neutral-100);
          }

          td {
            height: 50px;
            padding-right: 20px;
            position: relative;
            vertical-align: middle;
            text-align: right;

            @include max(1280px) {
              @include font-size(1.2rem);
            }

            &:first-child {
              padding-left: 9px;
              width: 259px;
              text-align: left;
              font-weight: 600;

              @include max(1440px) {
                padding-left: 10px;
                width: 250px;
              }

              @include max(1024px) {
                width: 140px;
                min-width: 70px;
              }
            }

            &:nth-child(3) {
              text-align: right;
              padding: 10px 43px 10px 0;

              @include max(1280px) {
                padding: 10px 19px 10px 0;
              }
            }

            &:nth-child(5) {
              text-align: center;
              // @include max(1280px) {
              //     padding-right: 0 !important;
              // }

              .icon-container {
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: center;
                cursor: pointer;

                div {
                  background: var(--neutral-200);
                  border-radius: 3px;
                  width: 65px;
                  padding: 6px 0;
                  display: inline-flex;
                  align-items: center;
                  gap: 6px;
                  justify-content: center;

                  &:hover {

                    //background: var(--secondary-2);
                    em {
                      color: var(--white);
                    }

                    span {
                      color: var(--white);
                      border-color: var(--white);
                    }
                  }

                  span {
                    width: 17px;
                    height: 17px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid var(--neutral-700);
                    color: var(--neutral-700);
                    background: transparent;
                    border-radius: 50%;
                  }
                }

                span {
                  &:hover {
                    background: var(--neutral-500);
                  }

                  border-radius: 3px;
                  width: 30px;
                  height: 30px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  @include max(1280px) {
                    border-radius: 3px;
                    width: 22px;
                    height: 22px;
                    font-size: 1.4em;
                    color: var(--neutral-700);
                  }

                  i {
                    color: var(--neutral-700);

                    &.icn-clock {
                      font-size: 19px;
                    }

                    &.icn-edit {
                      font-size: 20px;
                    }
                  }
                }
              }
            }

            &:nth-child(6) {
              text-align: center;

              span:not(.switch-handle) {

                &.checked-for-sale,
                &:hover,
                &.customer-selected {

                  //background: var(--secondary-2);
                  i,
                  em {
                    color: var(--white);
                  }
                }

                //cursor: pointer;
                //background: var(--neutral-500);
                padding: 10px 29px;


                i {
                  color: var(--black);
                  font-size: 16px;
                }
              }
            }

            .notexpanded,
            .expanded {
              // position: absolute;
              left: 0;
              top: 50%;
              transform: translateX(-69%);
              background-color: var(--neutral-200);
              width: 27px;
              height: 49px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              @include max(1440px) {
                transform: translateX(-36%);
              }

              .arrowRight {
                right: 11px;
                transition: .3s;
              }
            }

            .notexpanded {
              background-color: var(--neutral-100);
            }

            .expanded {
              background-color: var(--neutral-200);

              .arrowRight {
                transform: rotate(45deg);
                right: 9px;
              }
            }
          }
        }
      }
    }
  }

  .icone-info {
    flex: 1 1 10px;
    max-width: 10px;

    @include max(1280px) {
      display: flex;
    }

    i {
      color: var(--neutral-200);
      font-size: 13px;
      padding-left: 4px;

      @include max(1280px) {
        font-size: 11px;
      }
    }
  }

  .block-description {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-top: 12px;

    .title-description {
      text-transform: uppercase;
      font-size: 1.1rem;
    }

    .item-description {
      display: flex;
      align-items: baseline;

      &:nth-child(2) {
        flex-direction: column;
      }

      textarea {
        width: 100%;
        border: 1px solid var(--neutral-700);
        border-radius: 3px;
        font-size: 1.1rem;
        height: 80px;
        line-height: 1.6;
      }
    }
  }
}

.title-uppercase {
  text-transform: uppercase;
  font-weight: 700;
}

.form__item.form__item--right {
  .block-pack-name {
    padding: 0;
    border: 0;
    height: initial;

    .block-img-info {
      align-items: start;

      .block-middle-img-info {
        text-align: left;
      }

      .block-bottom-img-info {
        justify-content: start;
        flex-direction: column;
        align-items: start;

        .btn {
          margin: 0;
        }
      }
    }
  }
}

.item-content-filtre {
  .box-select {
    padding: 0 !important;

    .ng-select {
      .ng-select-container {
        position: initial !important;
        padding: 10px;

        .ng-value-container {
          .ng-input {
            top: 0;
          }
        }
      }
    }

    input[type="text"] {
      height: 39px;
      padding-left: 11px !important;
    }
  }

  &:first-child {
    .block-flex {
      input[type="text"] {
        min-width: initial;
      }
    }
  }
}


.main-modal {
  ng-select {
    .ng-dropdown-panel {
      top: 30px;

      @include max(1280px) {
        top: 21px;
      }
    }
  }
}

img {
  overflow: hidden;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.w-100 {
  width: 100%;
}

button {
  &.button-dark {
    background: var(--neutral-700) 0% 0% no-repeat padding-box !important;
    border: 1px solid var(--neutral-100);
    border-radius: 3px;
    color: var(--white);
    font-size: 1.4rem;
    white-space: nowrap;
    padding: 6px;
    cursor: pointer;
  }

  // &.icn-leave, &.icn-see-detail,
  &[class^="icn-"] {
    background: var(--neutral-200) 0% 0% no-repeat padding-box;
    border: 1px solid var(--white);
    border-radius: 3px;
    font-size: 1.5rem;
    padding: 5px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--primary);
    }
  }

  &.button-gray {
    background-color: var(--neutral-100) !important;
    border: 1px solid var(--neutral-200) !important;
    border-radius: 3px;
    font-size: 1.1rem;
    white-space: nowrap;
    padding: 6px;
    color: var(--neutral-500);
    cursor: pointer;
    text-transform: uppercase;
  }
}

// count result

.block-table-user-top {
  display: flex;
  justify-content: space-between;

  .block-table-user-top-item {
    font-weight: 600;
    font-size: 1.6rem;

    @include max(1280px) {
      font-size: 1.4rem;
    }

    &:first-child {
      span {
        padding-left: 0;
      }
    }
  }

  .block-table-user-top-item-right {
    display: flex;
    column-gap: 20px;
    align-items: center;
    color: var(--neutral-300);

    .item-sub-content-right {
      display: flex;
      column-gap: 20px;
      cursor: pointer;

      .active {
        color: initial;
      }
    }
    .separator-item-top{
      height: 30px;
      border-left:1px solid var(--neutral-500);
    }
    .icn-icn-export{ 
     &::before{
      color: var(--black)!important;
     }
    }
    span{
      display: flex;
      align-items: center;
    }
    ._export-aff {
      position: relative;
      cursor: pointer;

      &::before {
        // content: "";
        position: absolute;
        width: 1.6px;
        height: 17px;
        display: block;
        // background: #cccccc;
        right: 29px;
        top: -4px;
        pointer-events: none;
      }
    }
  }
}

.pb-4 {
  padding-bottom: 4px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pt-9 {
  padding-top: 9px;
}
.mb-10 {
  margin-bottom: 10px;
}
.ptb-11 {
  padding-top: 11px;
  padding-bottom: 11px;
}

.block-content-table-list {
  min-height: 253px;
  position: relative;

  &::before {
    content: '';
    width: 1px;
    height: 89%;
    background-color: var(--neutral-100);
    position: absolute;
    top: 35px;
    right: -10px;
  }

  td {
    &:first-child {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  .block-left {
    max-width: 62px !important;
    max-height: 62px !important;

    img {
      height: 62px !important;
      width: 62px !important;
    }
  }
}

.item-create-pack {
  &.block-btn-add-articles {
    button.black {
      font-size: 1.2rem;
      display: flex;
      column-gap: 11px;
      min-width: 213px;
    }
  }
}

// put with the flx-align-center class
.align-left {
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: left;
}

.align-center {
  display: flex;
  justify-content: center!important;
  align-items: center!important;
  text-align: center!important;
}

.align-right {
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: right;

  .blockCenter {
    justify-content: right !important;
  }
}


.table-liste-caisse,
.table-list-various-entry {
  .client__list table tbody tr td {
    &:first-child {
      padding: 10px 20px !important;
    }

    &:nth-child(5) {
      text-align: initial !important;
    }
  }

  thead {
    th {
      vertical-align: middle !important;

      &:first-child {
        width: 228px !important;
      }
    }
  }

} 
.client__list{
  table{
    // min-width: 1000px;
    // overflow-x: auto;
  }
}
.main-modal{
  table{
    min-width: inherit; 
  }
}
.liste-shop {
  .client__list {
    table {
      tr {
        td {
          &:first-child {
            padding-left: 20px !important;
          }
        }
      }
    }
  }
}

.title-table-filtre {
  @include font-size(1.8rem);

  @include max(1024px) {
    @include font-size(1.6rem);
  }

  font-weight: 700;
  color: var(--black);
  margin-bottom: 0;
  font-family: var(--font-family1);
  text-transform: none;

  @include max(1280px) {
    font-size: 1.5rem;
  }
}

app-catalogue {
  td {
    &:first-child {
      .block-description-article {
        // padding-left: 20px;
      }
    }
  }
}

// column td width
.w-sm {
  width: 178px;
}

.w-md {
  width: 223px !important;
}

.w-lg {
  width: 383px !important;
}

.w-auto {
  width: auto;
  text-align: left;
}

.box-select {
  &:hover {

    .ng-select+.arrow,
    .ng-autocomplete+.arrow {
      background-color: var(--primary) !important;
      cursor: pointer !important;
    }
  }
}

.table,
.info-state {
  .icn-check {
    color: var(--success);
    background-color: #C4FFDD;
    padding: 5px 3px 2px 3px;
    border-radius: 50%;
    font-size: 1rem;
    display: inline-block;
  }

  .icn-alert {
    color: var(--danger);
    background-color: #FFB1B5;
    padding: 5px 3px 2px 3px;
    border-radius: 50%;
    font-size: 9px;
    display: inline-block;
    width: 15px;
    height: 16px;
    margin: 0;
    position: relative;
    margin-bottom: -5px;

    &::before {
      display: block;
      position: absolute;
      top: 4px;
      left: 6.6px;
    }
  }
}


.pt-10 {
  padding-top: 10px;
}

.h-18 {
  height: 18px;
  display: inline-block;
}

#product-added,
#product-added-exit {
  .client__list {
    height: initial;
  }
}

.p-sm {
  padding: 10px 5px 5px 0;
}


.inline-flx-right {
  p {
    width: 100%;
    text-align: end;
    font-size: 10px;
    font-family: var(--font-family1);
    font-weight: 400;
    color: var(--neutral-700);
    padding-right: 50px;
  }

}

.title-content-filtre {
  color: var(--neutral-500);
  font-size: 1.4rem;
  font-weight: 600;
}

.tri-table {
  display: none;
}

.btn-thin-lg {
  button {
    font-size: 1.2rem;
    display: flex !important;
    column-gap: 11px;
    min-width: 213px !important;
  }
}

.icon-bg {
  position: relative;

  .info-state {
    position: absolute;
    bottom: 2px;
    right: 4px;

    @include max(1280px) {
      bottom: -3px;
      right: -3px;
    }

    i {
      border: 3px solid var(--primary);
      border-radius: 50%;
      background-color: var(--white);
      padding: 2px;
      font-size: 7px;
      font-weight: 800;
      &.icn-check{
        color: var(--black);
      }  
      @include max(1280px) {
        font-size: 4px !important;
      }

      &.icn-alert {
        padding: 5px;

        &::before {
          display: block;
          position: absolute;
          top: 1px;
          left: 4.6px;
          font-weight: normal;
        }
      }
    }
  }
}


.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  background: transparent;
  animation: fadeIn ease-in-out 0.3s;
  svg {
    @include max(1280px) {
      width: 70% !important;
    }
  }
}

.block-bold {
  display: inline-flex;
  height: 100%;
  width: 100%;

  .extra-bold {
    font-weight: 800;
    display: flex;
    align-items: center;

    span {
      &:not(.icn-icon_right_rrow) {
        font-size: 1.6rem;

        @include max(1280px) {
          font-size: 14px;
        }
      }

      &.icn-icon_right_rrow {
        display: none;
      }
    }

    &.danger {
      color: var(--danger);

      &.up {
        .icn-icon_right_rrow {
          font-size: 8px;
          position: relative;
          padding-right: 15px;
          display: block;

          &::before {
            transform: rotate(-90deg);
            top: -4px;
            position: absolute;
          }
        }

      }

      &.down {
        .icn-icon_right_rrow {
          font-size: 8px;
          position: relative;
          padding-right: 15px;
          display: block;

          &::before {
            transform: rotate(90deg);
            top: -4px;
            position: absolute;
          }
        }
      }
    }

    &.success {
      color: var(--success);
    }

  }
}

td {
  vertical-align: middle;
}

.box-inputs {
  position: relative;
  display: inline-block;
  width: 217px;
  border-radius: 3px;
  border: 1px solid transparent;

  .bold {
    font-weight: 700;

  }
}

.icn-check {
  color: var(--success);
}

.icn-alert {
  color: var(--danger);
}

img[src$="reduction.svg"] {
  width: 41px;

  @include max(1280px) {
    width: 31px;
  }
}

app-list-inventory {
  td {
    &:last-child {
      padding: 0 20px 0 0 !important;
    }
  }
}

.bold {
  font-weight: 700;
}

app-customer {
  .client__list {
    em[class*="icn-"] {
      color: var(--black);
    }
  }
}

.icn-see-detail {
  font-size: 18px !important;
  position: relative;
}


.text-underline {
  text-decoration: underline;
}

app-user-list {
  app-user-table-item {
    [data-tooltip]:not(.tooltip-disabled)::after {
      width: max-content;
    }
  }
}

app-reception,
app-create-inventory {
  ._export-aff {
    display: block;
  }
}

app-reception {

  .user-action {
    @include min(1280px) {
      height: 100%;
    }
  }
}

input[type="checkbox"][disabled] {
  cursor: not-allowed !important;
  +label {
    opacity: .5;
    cursor: not-allowed !important;
  }
}

input[type="checkbox"].disabled {
  cursor: not-allowed !important;
  +label {
    opacity: .5;
    cursor: not-allowed !important;
  }
}

app-creat-pack-item {
  .client__list {
    @include min(1280px) {
      height: initial;
    }
  }
}

.text-error {
  color: var(--danger) !important;
}

app-document {
  app-table-form {
    tbody {
      tr {
        td {
          &:nth-child(2) {
            .box-relative {
              width: 178px;
              .block-col-name {
                .block-right {
                  .align-end {
                    span {
                      &:first-child {
                        font-weight: bold;
                      }
                    }
                  }
                }
              }
            }

          }
        }
      }
    }
  }
}

.document-table-doc {
  max-height: calc(100vh - 254px);
  @include max(1280px) {
  max-height: calc(100vh - 188px);
  }
  overflow-y: auto;
  .client__list {
    overflow: initial;
  }
}

[ng-reflect-id="document-list"] {
  button {
    &[data-tooltip]:not(.tooltip-disabled)::after {
      width: 58px;
    }
  }
  .client__list {
    overflow: initial !important;
  }
}


.document-table-doc {
  .block-table-user.pb-4 {
    padding-bottom: initial;
  }
}

.modal-grey-title {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0px;
  color: var(--neutral-500);
  &--sm {
    font-size: 11px;
  }
  &--left {
    text-align: left;
  }
}
[ng-reflect-id="document-list"] {
  button {
    &[data-tooltip]:not(.tooltip-disabled)::after {
      width: 58px;
    }
  }
  .client__list {
    overflow: initial !important;
  }
}


.document-table-doc {
  .block-table-user.pb-4 {
    padding-bottom: initial;
  }
}

.modal-grey-title {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0px;
  color: var(--neutral-500);
  &--sm {
    font-size: 11px;
  }
  &--left {
    text-align: left;
  }
}
.block-add-shop {
  width: 282px;
  height: 86px;
  border: 1px dashed var(--custom-bg-space-sale);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--neutral-100);
  flex: 1;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--neutral-500);
  text-transform: uppercase;
   @include max(1604px) {
      width: 240px;
      height: 73px;
      font-size: 1.1rem;
  }
  @include max(1024px) {
      width: 203px;
      height: 63px;
  }

  .block-content-add-shop {
    position: relative;
    span {
      &:first-child {
        font-size: 20px;
        position: absolute;
        left: -17px;
        top: -4px;
        @include max(1604px) {
          font-size: 16px;
        }
        @include max(1280px) {
            font-size: 16px;
            top: -3px;
        }
      }
    }
  }

  &--sm {
    margin-top: 0;
    flex: 1;
  }
}

.text-grey {
  color: var(--neutral-500);
  @include max(1280px) {
  font-size: 1.3rem;
  }
}

[dialogid="add-caisse"] {
  .form-group {
    margin-bottom: 15px;
    display: flex;
    label {
      color: var(--black);
      font-size: 1.2rem;
      font-family: var(--font-family1);
      font-weight: 600;
      text-transform: uppercase;
      padding: 7px 10px;
      padding-left: 0;
      width: 212px;
      margin-right: 15px;
      display: inline-block;
    }
    .cnt-field {
      width: 100%;
      input {
        width: 100%;
        font-size: 1.2rem;
        font-family: var(--font-family1);
        border-radius: 3px;
        color: var(--black);
        padding: 5px;
        height: 30px;
        font-weight: 600;
        border: 1px solid var(--neutral-200);
      }

    }
  }
}

.home {
  &-child {
    .action__list {
      height: initial !important;
      overflow: initial !important;
      min-height: initial !important;
      row-gap: 5px;
    }
  }
}

app-creat-pack-item {
  .client__list {
    height: initial !important;
  }
}

.btn-group {
  >div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.form-btn-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-top {
  border-top: 2px solid var(--neutral-200);
  margin-top: 25px
}

.text-danger {
  color: var(--danger);
}
.link {
  position: relative;
  @include font-size(1.4rem);
  color: #898989;
  cursor: pointer;
  font-weight: 500;
  &--intern {
    letter-spacing: -0.35px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    @include max(1280px) {
      @include font-size(1.2rem);
    }
  }
    
  [class^="icn"] {
    font-size: 7px;
    font-weight: 100;
    @include max(1280px) {
      font-size: 1rem;
    }
  }

  &:hover {
    font-weight: 600;
    color: var(--black);
  }
}


.align-items-center {
  align-items: center !important;
}

.opacity-0 {
  opacity: 0;
}

.icn-grey {
  color: var(--neutral-200);
}

.state-text {
  font-size: 2.8rem;
  font-weight: bold;
  @include max(1280px) {
    font-size: 2.2rem;
  }
  [class^="icn"] {
    background: var(--secondary-2);
    width: 35px;
    height: 35px;
    display: block;
    border-radius: 50%;
    color: var(--white);
    font-size: 1.8rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @include max(1280px) {
      width: 25px;
      height: 25px;
      font-size: 1.3rem;
    }
  }
  &--success {
    color: var(--success);
    display: flex;
    align-items: center;
    column-gap: 15px;
    [class^="icn"] {
      background: var(--success);
    }
  }
  &--danger {
    color: var(--secondary-1);
    display: flex;
    align-items: center;
    column-gap: 15px;
    [class^="icn"] {
      background: var(--secondary-1);
    }
  }
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    font-weight: 600;
    font-family: var(--font-family1);
    cursor: pointer;
    @include font-size(1.4 rem);
    min-width: 135px;
    transition: all ease-in-out 0.2s;

    &.btn-cancel {
      background: #f1f1f1;
      color: #a2a2a2 !important;

      &:hover {
        color: #111111 !important;
      }
    }

    &.btn-save {
      background: var(--primary);

      &:hover {
        opacity: .7;
      }
    }

    margin-right: 30px;
    text-transform: uppercase;
  }
}

.form-group {
  .readonly {
    border: 0 !important;
    pointer-events: none;
  }
  .disabled {
    border: 0 !important;
    color: var(--neutral-500) !important;
    pointer-events: none;
    font-weight: bold;
  }
}

.grey {
  &-300 {
    color: var(--black);
  }
  &-200 {
    color: var(--neutral-700);
  }
  &-100 {
    color: var(--neutral-500);
  }
}

.text-uppercase{ text-transform: uppercase!important;}
.text-align-center{text-align: center!important;}
.mb-5{ margin-bottom: 3em;}
.mb-4{ margin-bottom: 2em;}
.mb-3{ margin-bottom: 1.5em;}
.mb-2{ margin-bottom: 1em;}
.mb-1{ margin-bottom: 0,5em;}

.inline-child{
  > *{
    display: inline-block;
    vertical-align: top;
    margin: 0 5px ;
  }
}

.form-group__radio {

  input[type="radio"] {
    filter: grayscale(1) !important;
    width: initial !important;
    height: initial !important;
    padding-right: 10px !important;
  }

  label {
    font-size: 1.2rem;
    font-weight: 600;
    font-family: var(--font-family1);
    cursor: pointer;
    position: relative;
    padding-left: 5px;
  }
}

.text-uppercase {
  text-transform: uppercase;
}
.choisissez-radio {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--black);
  &--group {
    display: flex;
    column-gap: 10px;
  }
}
.form-transaction {
  width: 120px;
  color: var(--neutral-500) !important;
  .form-group__radio {
    display: flex;
    justify-content: end;
  }
  label {
    color: var(--neutral-500) !important;
    font-weight: 600 !important;
  }
}

.column-type-3 {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  @include max(1280px) {
    gap: 20px;
  }
}

.justify-center {
  display: flex;
  justify-content: center;
  span {
    &:nth-child(2) {
      width: auto !important;
    }
  }
}

.fs-44{
  font-size: 44px;
}
.fs-35{
  font-size: 35px;
}
.fs-100{
  font-size: 100px;
  @include max(1280px) {
    font-size: 77px;
  }
  @include max(1024px) {
    font-size: 68px;
  }
}
.fs-35{
  font-size: 35px;
}
.fs-80{
  font-size: 81px;
}
.mw-full{
  max-width: inherit!important;
}
input.readonlyMiddle {
  position: relative;
  top: -3px;
}
 
.customMessagePosition{
  flex: 1 1 100%;
  @include min(1200px) {
    position: absolute;
    top: 75px;
    max-width: 90%; 
  } 
}
.block-content-shop{
  &.is-not-update{ 
    .shop-list{
      .card__close{
        visibility: hidden!important;
        opacity: 0!important;
      }
    }
  }
  .shop-list{
    .card__close{
      visibility: visible!important;
      opacity: 1!important;
    }
  }
}

.autocomplete-container {
    .input-container{
      input{ 
       cursor: default;
      }   
    }
}


.article-content{ 
  &.custom-view{
    .article-content{
      border-bottom: 0;
    }
    .block-article-unit {
      &.block-unit{ 
       .icn-alert{
        margin-bottom: 2px;
        margin-left: 15px;
       } 
       .icn-check{ 
        margin-left: 15px;
       } 
       .grey-icon {
        transform: translateY(0px);
        font-size: 25px;
        color: #bbb;
       }
       .mat-form-field-infix{
        padding-bottom: 0!important;
       }
       
      }
    }
    .block-inline-flx{
      .article-lot__trash{
        position: relative;
        top: -5px;
      }
    }
  }
  }
  .sell-table{ 
    tbody{
      td{
        &:first-child{
          padding-left: 0!important;
          max-width: 50px;
          font-weight: 700!important; 
        }
        &:nth-child(2){
          span{
            span{
              &:first-child{
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }


.tab-input {
	width: 212px;
	padding: 7px 10px;
	border-radius: 5px;
	font-size: 12px;
	font-weight: 600;
	word-wrap: break-word;
	border: 1px solid var(--neutral-700);
}

.qte_input {
	width: 363px;
	height: 30px;
	padding: 7px 10px;
}

.qte_article_serie {
	padding: 7px 10px;
	height: 38px;
	border: 1px solid var(--neutral-200);
}

.input_commentaire {
	height: 35px;
	padding: 7px 10px;
	border: 1px solid var(--neutral-200);
}

.serial-number-status {
	display: flex;
	justify-content: space-between;

	&__badge {
		width: 313px;
		height: 26px;
		background: var(--neutral-200);
		border-radius: 3px;
		padding: 5px 10px;

		span {
			width: 100%;
			text-align: center;
		}
	}

	.icn-content {
		font-size: 24px;

		.icn-warning {
			color: var(--danger);
		}

		.icn-like {
			color: var(--success);
		}
	}
	
}

.number-content {
	display: flex;

	&_begin {
		margin-right: 20px;
		input {
			border: 1px solid var(--neutral-300);
		}
	}

	&_end {
		input {
			border: 1px solid var(--primary);
		}
	}

	&_begin, &_end {
		display: flex;
		gap: 20px;
		align-items: center;

		&--title {
			text-transform: uppercase;
		}
		
		input {
			height: 30px;
			border-radius: 5px;
		}
	}
}

.new {
	width: 63px;
	background: #0065FF;
	border-radius: 3px;
	color: #fff;
	text-align: center;
	font-size: 10px;
	font-weight: 400;
}
