@use "variable";

@font-face {
  font-family: 'pos';
  src:  url('#{variable.$pathFontIcon}/pos.eot?eo3p27');
  src:  url('#{variable.$pathFontIcon}/pos.eot?eo3p27#iefix') format('embedded-opentype'),
    url('#{variable.$pathFontIcon}/pos.ttf?eo3p27') format('truetype'),
    url('#{variable.$pathFontIcon}/pos.woff?eo3p27') format('woff'),
    url('#{variable.$pathFontIcon}/pos.svg?eo3p27#pos') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icn-"], [class*=" icn-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'pos' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icn-last_connection:before {
  content: "\e939";
  color: #4f4f4f;
}
.icn-warning:before {
  content: "\e930";
}
.icn-close-square:before {
  content: "\e92f";
}
.icn-see-detail:before {
  content: "\e922";
}
.icn-import-item:before {
  content: "\e92e";
}
.icn-import-shop:before {
  content: "\e92b";
}
.icn-bad:before {
  content: "\e932";
}
.icn-good:before {
  content: "\e979";
}
.icn-item-sold:before {
  content: "\e97f";
}
.icn-last-connection:before {
  content: "\e980";
}
.icn-profil-man:before {
  content: "\e981";
}
.icn-profil-man-outline:before {
  content: "\e982";
}
.icn-profil-woman:before {
  content: "\e983";
}
.icn-profil-woman-outline:before {
  content: "\e984";
}
.icn-turnover:before {
  content: "\e994";
}
.icn-quick-access:before {
  content: "\e99f";
}
.icn-export-category:before {
  content: "\e9a0";
}
.icn-miscellaneous-output:before {
  content: "\e9a9";
}
.icn-delete-shop:before {
  content: "\e9bc";
}
.icn-change-shop:before {
  content: "\e9c9";
}
.icn-export-shop:before {
  content: "\e9cc";
}
.icn-change-item:before {
  content: "\e9d1";
}
.icn-delete-category-customer:before {
  content: "\e9d3";
}
.icn-change-category-customer:before {
  content: "\e9d5";
}
.icn-add-category-customer:before {
  content: "\e9d6";
}
.icn-import-category-customer:before {
  content: "\e9d7";
}
.icn-export-category-customer:before {
  content: "\e9d8";
}
.icn-import-customer:before {
  content: "\e9d9";
}
.icn-add-customer:before {
  content: "\e9db";
}
.icn-export-customer:before {
  content: "\e9dc";
}
.icn-change-customer:before {
  content: "\e9dd";
}
.icn-delete-customer:before {
  content: "\e9de";
}
.icn-create-item-package:before {
  content: "\e9df";
}
.icn-import-category:before {
  content: "\e9e0";
}
.icn-delete-item:before {
  content: "\e9e1";
}
.icn-export-item:before {
  content: "\e9e2";
}
.icn-add-item:before {
  content: "\e9e4";
}
.icn-miscellaneous-input:before {
  content: "\e9e5";
}
.icn-empty-page:before {
  content: "\e9e8";
}
.icn-profile--employee:before {
  content: "\e9eb";
}
.icn-increase:before {
  content: "\e9ec";
}
.icn-decrease:before {
  content: "\e9ed";
}
.icn-add-checkout:before {
  content: "\e9ef";
}
.icn-change-category:before {
  content: "\e9f0";
}
.icn-add-category:before {
  content: "\e9f1";
}
.icn-delete-category:before {
  content: "\e9f2";
}
.icn-add-shop:before {
  content: "\e9f3";
}
.icn-discount:before {
  content: "\e9f4";
}
.icn-safe:before {
  content: "\eaa0";
}
.icn-write:before {
  content: "\e903";
}
.icn-add-ellipse:before {
  content: "\e905";
}
.icn-add-square:before {
  content: "\e942";
}
.icn-anchor-link:before {
  content: "\e943";
}
.icn-archive:before {
  content: "\e944";
}
.icn-arrow-decrease:before {
  content: "\e945";
}
.icn-arrow-diagonal:before {
  content: "\e946";
}
.icn-arrow-diagonal-double:before {
  content: "\e947";
}
.icn-arrow-diagonal-double-in:before {
  content: "\e948";
}
.icn-arrow-diagonal-double-opp:before {
  content: "\e949";
}
.icn-arrow-diagonal-opp:before {
  content: "\e94a";
}
.icn-arrow-double-horizontal:before {
  content: "\e94b";
}
.icn-arrow-double-horizontal-in:before {
  content: "\e94c";
}
.icn-arrow-double-vertical:before {
  content: "\e94d";
}
.icn-arrow-double-vertical-in:before {
  content: "\e94e";
}
.icn-arrow-down:before {
  content: "\e94f";
}
.icn-arrow-down-1:before {
  content: "\e950";
}
.icn-arrow-down-up:before {
  content: "\e951";
}
.icn-arrow-down-up-in:before {
  content: "\e952";
}
.icn-arrow-dual-horizontal:before {
  content: "\e953";
}
.icn-arrow-dual-vertical:before {
  content: "\e954";
}
.icn-arrow-income:before {
  content: "\e955";
}
.icn-arrow-left:before {
  content: "\e957";
}
.icn-arrow-left-right:before {
  content: "\e958";
}
.icn-arrow-left-right-in:before {
  content: "\e959";
}
.icn-arrow-next:before {
  content: "\e95a";
}
.icn-arrow-next-small:before {
  content: "\e95b";
}
.icn-arrow-outcome:before {
  content: "\e95c";
}
.icn-arrow-prev:before {
  content: "\e95d";
}
.icn-arrow-prev-small:before {
  content: "\e95e";
}
.icn-arrow-resize:before {
  content: "\e95f";
}
.icn-arrow-right:before {
  content: "\e960";
}
.icn-arrow-up:before {
  content: "\e961";
}
.icn-arrow-up-1:before {
  content: "\e962";
}
.icn-at:before {
  content: "\e964";
}
.icn-attach:before {
  content: "\e965";
}
.icn-other-nav:before {
  content: "\e967";
}
.icn-backspace:before {
  content: "\e968";
}
.icn-trash-2:before {
  content: "\e969";
}
.icn-bell:before {
  content: "\e96a";
}
.icn-bell-off:before {
  content: "\e96b";
}
.icn-bell-ringing:before {
  content: "\e96c";
}
.icn-goal:before {
  content: "\e96d";
}
.icn-bluetooth:before {
  content: "\e96e";
}
.icn-bluetooth-off:before {
  content: "\e96f";
}
.icn-bookmark:before {
  content: "\e970";
}
.icn-box:before {
  content: "\e971";
}
.icn-break-link:before {
  content: "\e972";
}
.icn-brightness:before {
  content: "\e973";
}
.icn-bug:before {
  content: "\e974";
}
.icn-bulb:before {
  content: "\e975";
}
.icn-bullhorn:before {
  content: "\e976";
}
.icn-call-cancel:before {
  content: "\e978";
}
.icn-call-miss:before {
  content: "\e97a";
}
.icn-call-out:before {
  content: "\e97b";
}
.icn-call-pause:before {
  content: "\e97c";
}
.icn-call-receive:before {
  content: "\e97d";
}
.icn-category:before {
  content: "\e986";
}
.icn-chat:before {
  content: "\e987";
}
.icn-chat-block:before {
  content: "\e988";
}
.icn-chat-new:before {
  content: "\e989";
}
.icn-chat-pending:before {
  content: "\e98a";
}
.icn-chat-remove:before {
  content: "\e98b";
}
.icn-chat-success:before {
  content: "\e98c";
}
.icn-chat-unread:before {
  content: "\e98d";
}
.icn-chat-writing:before {
  content: "\e98e";
}
.icn-checkmark:before {
  content: "\e98f";
}
.icn-checkmark-small:before {
  content: "\e990";
}
.icn-circle-checkmark:before {
  content: "\e991";
}
.icn-close:before {
  content: "\e992";
}
.icn-close-ellipse:before {
  content: "\e993";
}
.icn-cloud:before {
  content: "\e995";
}
.icn-cloud-disconnect:before {
  content: "\e996";
}
.icn-cloud-success:before {
  content: "\e997";
}
.icn-component:before {
  content: "\e998";
}
.icn-connect:before {
  content: "\e999";
}
.icn-contacts:before {
  content: "\e99a";
}
.icn-copy:before {
  content: "\e99b";
}
.icn-cpu:before {
  content: "\e99c";
}
.icn-create-link:before {
  content: "\e99e";
}
.icn-cross:before {
  content: "\e9a1";
}
.icn-csv:before {
  content: "\e9a2";
}
.icn-cube:before {
  content: "\e9a3";
}
.icn-cup:before {
  content: "\e9a4";
}
.icn-cursor:before {
  content: "\e9a5";
}
.icn-dialpad:before {
  content: "\e9a6";
}
.icn-diamond:before {
  content: "\e9a7";
}
.icn-dice:before {
  content: "\e9a8";
}
.icn-discover-compass:before {
  content: "\e9aa";
}
.icn-dislike:before {
  content: "\e9ab";
}
.icn-display:before {
  content: "\e9ac";
}
.icn-document:before {
  content: "\e9ad";
}
.icn-dots-horizontal:before {
  content: "\e9ae";
}
.icn-dots-vertical:before {
  content: "\e9af";
}
.icn-download:before {
  content: "\e9b0";
}
.icn-drag:before {
  content: "\e9b1";
}
.icn-drop:before {
  content: "\e9b2";
}
.icn-duplicate:before {
  content: "\e9b3";
}
.icn-earth:before {
  content: "\e9b4";
}
.icn-edit:before {
  content: "\e9b5";
}
.icn-eye-off:before {
  content: "\e9b6";
}
.icn-eye-show:before {
  content: "\e9b7";
}
.icn-feedback:before {
  content: "\e9b8";
}
.icn-female:before {
  content: "\e9b9";
}
.icn-filter:before {
  content: "\e9ba";
}
.icn-fingerprint:before {
  content: "\e9bb";
}
.icn-flag:before {
  content: "\e9bd";
}
.icn-flag-triangle:before {
  content: "\e9be";
}
.icn-flashlight:before {
  content: "\e9bf";
}
.icn-folder-add:before {
  content: "\e9c1";
}
.icn-folder-block:before {
  content: "\e9c2";
}
.icn-folder-lock:before {
  content: "\e9c3";
}
.icn-folder-move:before {
  content: "\e9c4";
}
.icn-folder-remove:before {
  content: "\e9c5";
}
.icn-folder-remove-1:before {
  content: "\e9c6";
}
.icn-folder-share:before {
  content: "\e9c7";
}
.icn-forward:before {
  content: "\e9c8";
}
.icn-newspapper:before {
  content: "\e9cb";
}
.icn-user-2:before {
  content: "\e9d0";
}
.icn-info-ellipse:before {
  content: "\e9d4";
}
.icn-hashtag-2:before {
  content: "\ea1e";
}
.icn-game-controller:before {
  content: "\ea1f";
}
.icn-hand:before {
  content: "\ea21";
}
.icn-hashtag:before {
  content: "\ea22";
}
.icn-hdd:before {
  content: "\ea23";
}
.icn-heart:before {
  content: "\ea24";
}
.icn-history:before {
  content: "\ea26";
}
.icn-home:before {
  content: "\ea27";
}
.icn-hotspot:before {
  content: "\ea28";
}
.icn-sale-bold:before {
  content: "\ea2a";
}
.icn-image:before {
  content: "\ea2b";
}
.icn-inbox:before {
  content: "\ea2c";
}
.icn-info:before {
  content: "\ea2d";
}
.icn-joystick:before {
  content: "\ea2e";
}
.icn-key:before {
  content: "\ea2f";
}
.icn-keyboard:before {
  content: "\ea30";
}
.icn-keyboard-hide:before {
  content: "\ea31";
}
.icn-keyboard-show:before {
  content: "\ea32";
}
.icn-laptop:before {
  content: "\ea33";
}
.icn-layers:before {
  content: "\ea34";
}
.icn-levels:before {
  content: "\ea35";
}
.icn-like:before {
  content: "\ea36";
}
.icn-location:before {
  content: "\ea37";
}
.icn-location-define:before {
  content: "\ea38";
}
.icn-location-straight:before {
  content: "\ea39";
}
.icn-lock:before {
  content: "\ea3a";
}
.icn-log-in:before {
  content: "\ea3b";
}
.icn-log-out:before {
  content: "\ea3c";
}
.icn-mail:before {
  content: "\ea3d";
}
.icn-mail-open:before {
  content: "\ea3e";
}
.icn-male:before {
  content: "\ea3f";
}
.icn-map:before {
  content: "\ea40";
}
.icn-map-pin:before {
  content: "\ea41";
}
.icn-map-tag:before {
  content: "\ea42";
}
.icn-map-tag-add:before {
  content: "\ea43";
}
.icn-map-tag-minus:before {
  content: "\ea44";
}
.icn-map-tag-remove:before {
  content: "\ea45";
}
.icn-map-tag-user:before {
  content: "\ea46";
}
.icn-maximize:before {
  content: "\ea47";
}
.icn-best-seller:before {
  content: "\ea48";
}
.icn-navigation:before {
  content: "\ea49";
}
.icn-minimize:before {
  content: "\ea4a";
}
.icn-minus:before {
  content: "\ea4b";
}
.icn-monoblock:before {
  content: "\ea4d";
}
.icn-moon:before {
  content: "\ea4e";
}
.icn-mouse:before {
  content: "\ea4f";
}
.icn-move:before {
  content: "\ea50";
}
.icn-newspaper:before {
  content: "\ea51";
}
.icn-new-tab:before {
  content: "\ea52";
}
.icn-open-checkout:before {
  content: "\ea53";
}
.icn-credit-card:before {
  content: "\ea54";
}
.icn-payment-by-check:before {
  content: "\ea55";
}
.icn-paste:before {
  content: "\ea59";
}
.icn-pin:before {
  content: "\ea5b";
}
.icn-add:before {
  content: "\ea5c";
}
.icn-power-button:before {
  content: "\ea5d";
}
.icn-printer:before {
  content: "\ea5f";
}
.icn-rate:before {
  content: "\ea60";
}
.icn-re-load:before {
  content: "\ea64";
}
.icn-remove-ellipse:before {
  content: "\ea65";
}
.icn-remove-square:before {
  content: "\ea66";
}
.icn-reply:before {
  content: "\ea67";
}
.icn-report:before {
  content: "\ea68";
}
.icn-restrict:before {
  content: "\ea6a";
}
.icn-route:before {
  content: "\ea6b";
}
.icn-route-arrow:before {
  content: "\ea6c";
}
.icn-router:before {
  content: "\ea6d";
}
.icn-rss:before {
  content: "\ea6e";
}
.icn-scales:before {
  content: "\ea70";
}
.icn-scaner:before {
  content: "\ea71";
}
.icn-search:before {
  content: "\ea72";
}
.icn-send:before {
  content: "\ea73";
}
.icn-server:before {
  content: "\ea74";
}
.icn-services-mobile-money:before {
  content: "\ea75";
}
.icn-settings-2:before {
  content: "\ea76";
}
.icn-share:before {
  content: "\ea77";
}
.icn-share-1:before {
  content: "\ea78";
}
.icn-share-2:before {
  content: "\ea79";
}
.icn-shield:before {
  content: "\ea7a";
}
.icn-shield-attention:before {
  content: "\ea7b";
}
.icn-shield-checkmark:before {
  content: "\ea7c";
}
.icn-signal:before {
  content: "\ea7e";
}
.icn-smartphone:before {
  content: "\ea7f";
}
.icn-smartwatch:before {
  content: "\ea80";
}
.icn-smartwatch-1:before {
  content: "\ea81";
}
.icn-sort:before {
  content: "\ea82";
}
.icn-square-checkmark:before {
  content: "\ea83";
}
.icn-star:before {
  content: "\ea84";
}
.icn-sun:before {
  content: "\ea86";
}
.icn-switch:before {
  content: "\ea88";
}
.icn-tablet:before {
  content: "\ea89";
}
.icn-text:before {
  content: "\ea8a";
}
.icn-trash:before {
  content: "\ea8b";
}
.icn-umbrella:before {
  content: "\ea8c";
}
.icn-unlock:before {
  content: "\ea8d";
}
.icn-unpin:before {
  content: "\ea8e";
}
.icn-upload:before {
  content: "\ea8f";
}
.icn-usb:before {
  content: "\ea90";
}
.icn-user:before {
  content: "\ea91";
}
.icn-user-add:before {
  content: "\ea92";
}
.icn-user-block:before {
  content: "\ea93";
}
.icn-user-remove:before {
  content: "\ea94";
}
.icn-user-success:before {
  content: "\ea95";
}
.icn-user-wait:before {
  content: "\ea96";
}
.icn-view-columns:before {
  content: "\ea98";
}
.icn-view-grid:before {
  content: "\ea99";
}
.icn-view-grid-many:before {
  content: "\ea9a";
}
.icn-view-list:before {
  content: "\ea9b";
}
.icn-view-rows:before {
  content: "\ea9c";
}
.icn-webcam:before {
  content: "\ea9d";
}
.icn-wi-fi:before {
  content: "\ea9e";
}
.icn-wi-fi-off:before {
  content: "\ea9f";
}
.icn-save:before {
  content: "\e940";
}
.icn-payment-check:before {
  content: "\e937";
}
.icn-money-transfer:before {
  content: "\e938";
}
.icn-cash:before {
  content: "\e935";
}
.icn-ume:before {
  content: "\e936";
}
.icn-sim-card-icon:before {
  content: "\e934";
}
.icn-sim-card-1:before {
  content: "\e933";
}
.icn-combination:before {
  content: "\e931";
}
.icn-category-customer:before {
  content: "\e93f";
}
.icn-customer:before {
  content: "\e93e";
}
.icn-inventory:before {
  content: "\e93c";
}
.icn-logout:before {
  content: "\e93a";
}
.icn-ico-profile:before {
  content: "\e93b";
}
.icn-serial-number:before {
  content: "\e921";
}
.icn-cash-payment:before {
  content: "\e92a";
}
.icn-mobile-money:before {
  content: "\e92d";
}
.icn-help:before {
  content: "\e929";
}
.icn-camera:before {
  content: "\e928";
}
.icn-return:before {
  content: "\e927";
}
.icn-icon-right-rrow:before {
  content: "\e92c";
}
.icn-icn-grid:before {
  content: "\e924";
}
.icn-icn-list:before {
  content: "\e925";
}
.icn-icn-export:before {
  content: "\e926"!important;
}
.icn-etc:before {
  content: "\e920";
}
.icn-delete:before {
  content: "\e923";
}
.icn-item-out-of-stock:before {
  content: "\e90c";
}
.icn-heart2:before {
  content: "\e90a";
}
.icn-notification:before {
  content: "\e900";
}
.icn-message:before {
  content: "\e901";
}
.icn-check:before {
  content: "\e904";
}
.icn-stock-manager:before {
  content: "\e906";
}
.icn-item-in-stock:before {
  content: "\e907";
}
.icn-dashboard:before {
  content: "\e908";
}
.icn-sale:before {
  content: "\e909";
}
.icn-item:before {
  content: "\e90b";
}
.icn-paper:before {
  content: "\e90e";
}
.icn-info-fill:before {
  content: "\e915";
}
.icn-shop:before {
  content: "\e916";
}
.icn-user3:before {
  content: "\e918";
}
.icn-mods:before {
  content: "\e919";
}
.icn-setting:before {
  content: "\e91a";
}
.icn-checkout:before {
  content: "\e91b";
}
.icn-supervising:before {
  content: "\e91d";
}
.icn-alert:before {
  content: "\e90d";
}
.icn-vente-slim:before {
  content: "\e90f";
}
.icn-dash-categorie:before {
  content: "\e910";
}
.icn-tab-catalogue:before {
  content: "\e911";
}
.icn-leave:before {
  content: "\e912";
}
.icn-cart:before {
  content: "\e913";
}
.icn-edit2:before {
  content: "\e914";
}
.icn-profil:before {
  content: "\e917";
}
.icn-clock:before {
  content: "\e91c";
}
.icn-put-on-hold:before {
  content: "\e91e";
}
.icn-refersh:before {
  content: "\e91f";
}
