.fade-in {
        animation: fade-in 1s both;
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
        0% {
                opacity: 0;
        }

        100% {
                opacity: .87;
        }
}

.tracking-in-contract {
	-webkit-animation: tracking-in-contract 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

/**
 * ----------------------------------------
 * animation tracking-in-contract
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-in-contract {
        0% {
          letter-spacing: 1em;
          opacity: 0;
        }
        40% {
          opacity: 0.6;
        }
        100% {
          letter-spacing: normal;
          opacity: 1;
        }
      }
      @keyframes tracking-in-contract {
        0% {
          letter-spacing: 1em;
          opacity: 0;
        }
        40% {
          opacity: 0.6;
        }
        100% {
          letter-spacing: normal;
          opacity: 1;
        }
      }
      