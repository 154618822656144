@use 'variable';
@use 'mixins';

@include mixins.font(variable.$pathFont, 'Open Sans','OpenSans-LightItalic', 300, 'italic');
@include mixins.font(variable.$pathFont, 'Open Sans','OpenSans-Light', 300, 'normal');
@include mixins.font(variable.$pathFont, 'Open Sans','OpenSans-Italic', 'regular', 'italic');
@include mixins.font(variable.$pathFont, 'Open Sans','OpenSans-Regular', 'regular', 'normal');
@include mixins.font(variable.$pathFont, 'Open Sans','OpenSans-SemiBoldItalic', 600, 'italic');
@include mixins.font(variable.$pathFont, 'Open Sans','OpenSans-SemiBold', 600, 'normal');
@include mixins.font(variable.$pathFont, 'Open Sans','OpenSans-BoldItalic', bold, 'italic');
@include mixins.font(variable.$pathFont, 'Open Sans','OpenSans-Bold', bold, 'normal');
@include mixins.font(variable.$pathFont, 'Open Sans','OpenSans-ExtraBoldItalic', 800, 'italic');
@include mixins.font(variable.$pathFont, 'Open Sans','OpenSans-ExtraBold', 800, 'normal');