//path
$pathFont: "../fonts";
$pathFontIcon: "../fonts/font-icon";
$pathFontIconDesign: "../fonts/design-icon";
$pathImg: "assets/img";

// layout
$container: 1200px;
$containerPadding: 0 1.5rem;
$margin-p: 10px;
$borderRadius: 10px;
$borderRadius2: $borderRadius;

// color
$primary-1: var(--black);
$primary-2: var(--primary);
$primary-3: var(--neutral-700);
$primary-4: #ffb8bb;

// gray
$secondary-1: var(--neutral-100);
$secondary-2: var(--black);
$secondary-3: #eaecf1;
$secondary-4: var(--white);

// default
$fontFamily1: var(--font-family1);
$fontFamily2: "Sans Serif";
$main-color: var(--black);
$main-bg: var(--neutral-7);

$fontSize1: 1.4rem;
$fontSize2: 1.6rem;
$fontSize3: 1.1rem;

$lineHeight1: 1.6;
$lineHeight2: 1.2;

$lettreSpacing1: 0;
$lettreSpacing2: 0;

$thin: 100; // Thin (Hairline)
$extraLight: 200; // Extra Light (Ultra Light)
$light: 300; // Light
$normal: 400; // Normal
$medium: 500; // Medium
$semiBold: 600; // Semi Bold (Demi Bold)
$bold: 700; // Bold
$extraBold: 800; // Extra Bold (Ultra Bold)
$heavy: 900; // Black (Heavy)

// heading
$fontSize-h1: 2.6rem;
$marginBottom-h1: 20px;

// color

$fontSize-h2: 2.2rem;
$marginBottom-h2: 20px;

$fontSize-h3: 1.8rem;
$marginBottom-h3: 20px;

$fontSize-h4: 1.6rem;
$marginBottom-h4: 14px;

$fontSize-h5: 1.4rem;
$marginBottom-h5: 20px;

$fontSize-h6: 1.2rem;
$marginBottom-h6: 20px;

// link
$linkColor: var(--neutral-700);
$linkColor-hover: var(--primary);
$linkTextDecoration: none;

// scroll

// btn
$btn-fontSize: 1.6rem;
$btn-fontWeight: 500;
$btn-lineHeight: 1.3;
$btn-letterSpacing: 0px;
$btn-color: var(--black);
$btn-textTransform: uppercase;
$btn-bg: var(--neutral-700);
$btn-padding: 1rem 2rem;
$btn-borderRadius: 5px;
$btn-border: none;

$btn-bg-hover: #fdd633;

$btn2-color: var(--white);
$btn2-bg: #2c7bb9;

$btn2-bg-hover: #0f70b9;

$btnLite-color: var(--neutral-700);
$btnLite-bg: transparent !important;

$btnLite-hover: var(--neutral-200);
$btnLite-bg-hover: var(--neutral-700)!important;


